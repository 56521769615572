/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React, { useState } from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckbox, FieldRadioButton, ValidationError } from '../../components';

import css from './FieldCheckboxGroup.module.css';
import './CustomFieldCheckbox.css';

const FieldCheckboxRenderer = props => {
  const {
    className,
    rootClassName,
    label,
    twoColumns,
    id,
    fields,
    options,
    meta,
    selected_value,
    html,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;

  const [selected_val, setSelectedValue] = useState(selected_value);
  console.log('selected_val', selected_val);
  return (
    <fieldset className={`${classes} mainField`}>
      {label ? <legend>{label}</legend> : null}
      <ul className={listClasses}>
        {options?.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li
              key={fieldId}
              className={`${css.item} ${selected_val?.includes('worship_leader') ? 'disable_other_button' : ''
                }`}
            >
              <div className="field_textfield">
                <FieldCheckbox
                  // disable={
                  //   selected_val?.includes('worship_leader') && option.key != 'worship_leader'
                  //     ? true
                  //     : false
                  // }
                  id={fieldId}
                  name={fields.name}
                  label={option.label}
                  value={option.key}
                  is_checked={selected_val?.includes(option.key)}
                  onChange={data => {
                    if (selected_val?.includes(option.key)) {

                      const data = selected_val?.filter(item => item !== option.key);
                      // setSelectedValue([]);
                      setSelectedValue(data);

                    } else {

                      selected_val?.push(option.key);

                    }
                  }

                  }
                />
                {html(option.key)}
              </div>
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
};

const FieldCheckboxGroup = props => <FieldArray component={FieldCheckboxRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroup.propTypes = {
  name: string.isRequired,
};

export default FieldCheckboxGroup;
