import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import config from '../../config';
import { propTypes } from '../../util/types';
import './topbarcustom.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import Modal from '@material-ui/core/Modal';
import founder from '../../assets/founders.jpg';
import done from '../../assets/done.png';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
} from '../../components';
import { Form, Field } from 'react-final-form';
import { TopbarSearchForm, TopbarSearchFormHome } from '../../forms';
import { Box } from '@material-ui/core';
import css from './TopbarDesktop.module.css';
import topBarSearchCss from '../../forms/TopbarSearchForm/TopbarSearchFormHome.module.css';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { Link } from 'react-router-dom';
import OnBoardingModal from '../OnBoarding/OnBoardingModal';
const TopbarDesktopComponent = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedServiceList, setSelectedServiceList] = useState([]);
  function isToday(date) {
    const today = new Date();

    if (today?.toDateString() === date?.toDateString()) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    setMounted(true);

    const userCreatedAt = props?.currentUser?.attributes?.createdAt;
    const onBoardingStep = localStorage.getItem('onBoardingStep');
    if (isAuthenticated && userCreatedAt) {
      if (!onBoardingStep) {
        localStorage.setItem('onBoardingStep', 'step1');
      }
    }
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const setSelectedListOption = data => {
    setSelectedList(data);
  };

  const setSelectedService = data => {
    setSelectedServiceList(data);
  };

  const handleSubmit = values => {
    const { currentSearchParams } = props;
    console.log('props', props);
    // const keywords = selectedServiceList.map(({ id, name }) => `${name}`).join(',') !== "" ?
    //   selectedServiceList.map(({ name, id }) => `${name}`).join(',') :
    //   null;
    const daterange = values.daterange;
    const cityvalues =
      selectedList.map(({ name, id }) => `${id}`).join(',') !== ''
        ? selectedList.map(({ name, id }) => `${id}`).join(',')
        : null;
    console.log('selectedList', selectedList);
    const { history } = props;
    if (values.location !== '') {
      const { search, selectedPlace } = values.location;

      const { origin, bounds } = selectedPlace;

      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const searchParams = {
        ...currentSearchParams,
        ...originMaybe,

        address: search,
        bounds,

        pub_yogaStyles: values.keywords && 'has_any:' +values.keywords,
        dates: daterange,
        keywords: cityvalues,
      };
      console.log('searchParams', searchParams);
      // return
      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    } else {
      const searchParams = {
        pub_yogaStyles: values.keywords && 'has_any:' + values.keywords,
        dates: daterange,
        keywords: cityvalues,
      };
      console.log('searchParams11', searchParams);
      // return
      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    }

    // const searchParams = {
    //   ...currentSearchParams,
    //   ...originMaybe,
    //   address: search,
    //   pub_yogaStyles: keywords,
    //   bounds,
    // };
    // s?pub_yogaStyles=drummer%2Celectric_guitar

    // history.push(`/s?pub_yogaStyles=${keywords}`);
  };

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span style={{ position: 'relative' }}>
        <span className={css.inbox}>
          <svg
            width="30"
            height="22"
            viewBox="0 0 20 16"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: 'transparent' }}
          >
            <path
              d="M17.9166 12.9997L12.3809 7.99967M7.61901 7.99967L2.08332 12.9997M1.66663 3.83301L8.47073 8.59588C9.0217 8.98156 9.29719 9.1744 9.59685 9.2491C9.86154 9.31508 10.1384 9.31508 10.4031 9.2491C10.7027 9.1744 10.9782 8.98156 11.5292 8.59588L18.3333 3.83301M5.66663 14.6663H14.3333C15.7334 14.6663 16.4335 14.6663 16.9683 14.3939C17.4387 14.1542 17.8211 13.7717 18.0608 13.3013C18.3333 12.7665 18.3333 12.0665 18.3333 10.6663V5.33301C18.3333 3.93288 18.3333 3.23281 18.0608 2.69803C17.8211 2.22763 17.4387 1.84517 16.9683 1.60549C16.4335 1.33301 15.7334 1.33301 14.3333 1.33301H5.66663C4.26649 1.33301 3.56643 1.33301 3.03165 1.60549C2.56124 1.84517 2.17879 2.22763 1.93911 2.69803C1.66663 3.23281 1.66663 3.93288 1.66663 5.33301V10.6663C1.66663 12.0665 1.66663 12.7665 1.93911 13.3013C2.17879 13.7717 2.56124 14.1542 3.03165 14.3939C3.56643 14.6663 4.26649 14.6663 5.66663 14.6663Z"
              stroke="#667085"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span className={css.badge + ' ' + css.inboxBadge}>1</span>
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <span className={css.badge}></span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="EditListingPage">
          <OwnListingLink
            listing={currentUserListing}
            listingFetched={currentUserListingFetched}
            className={css.yourListingsLink}
          >
            <div>
              <div className="menu-drop-container">
                <span className="icon-menu">
                  <svg
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 18V15H4C2.34315 15 1 16.3431 1 18M5.8 21H13.8C14.9201 21 15.4802 21 15.908 20.782C16.2843 20.5903 16.5903 20.2843 16.782 19.908C17 19.4802 17 18.9201 17 17.8V4.2C17 3.07989 17 2.51984 16.782 2.09202C16.5903 1.71569 16.2843 1.40973 15.908 1.21799C15.4802 1 14.9201 1 13.8 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
                      stroke="#7F56D9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <div className="drop-content">
                  <p>My Listing</p>
                  <p className="desc">Manage your professional listing.</p>
                </div>
              </div>

              {/* {currentUserListing ? (
                <FormattedMessage id="TopbarDesktop.editYourListingLink" />
              ) : (
                <FormattedMessage id="TopbarDesktop.addYourListingLink" />
              )} */}
            </div>
          </OwnListingLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <div className="menu-drop-container">
              <span className="icon-menu">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.00002 20.8174C3.6026 21 4.41649 21 5.8 21H16.2C17.5835 21 18.3974 21 19 20.8174M3.00002 20.8174C2.87082 20.7783 2.75133 20.7308 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H16.2C17.8802 1 18.7202 1 19.362 1.32698C19.9265 1.6146 20.3854 2.07354 20.673 2.63803C21 3.27976 21 4.11984 21 5.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C19.2487 20.7308 19.1292 20.7783 19 20.8174M3.00002 20.8174C3.00035 20.0081 3.00521 19.5799 3.07686 19.2196C3.39249 17.6329 4.63288 16.3925 6.21964 16.0769C6.60603 16 7.07069 16 8 16H14C14.9293 16 15.394 16 15.7804 16.0769C17.3671 16.3925 18.6075 17.6329 18.9231 19.2196C18.9948 19.5799 18.9996 20.0081 19 20.8174M15 8.5C15 10.7091 13.2091 12.5 11 12.5C8.79086 12.5 7 10.7091 7 8.5C7 6.29086 8.79086 4.5 11 4.5C13.2091 4.5 15 6.29086 15 8.5Z"
                    stroke="#7F56D9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div className="drop-content">
                <p>My Profile</p>
                <p className="desc">Manage your personal settings.</p>
              </div>
            </div>

            {/* <FormattedMessage id="TopbarDesktop.profileSettingsLink" /> */}
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <div className="menu-drop-container">
              <span className="icon-menu">
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.0005 14C12.6573 14 14.0005 12.6569 14.0005 11C14.0005 9.34315 12.6573 8 11.0005 8C9.34363 8 8.00049 9.34315 8.00049 11C8.00049 12.6569 9.34363 14 11.0005 14Z"
                    stroke="#7F56D9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.28957 18.3711L8.87402 19.6856C9.04776 20.0768 9.3313 20.4093 9.69024 20.6426C10.0492 20.8759 10.4681 21.0001 10.8962 21C11.3244 21.0001 11.7433 20.8759 12.1022 20.6426C12.4612 20.4093 12.7447 20.0768 12.9185 19.6856L13.5029 18.3711C13.711 17.9047 14.0609 17.5159 14.5029 17.26C14.9477 17.0034 15.4622 16.8941 15.9729 16.9478L17.4029 17.1C17.8286 17.145 18.2582 17.0656 18.6396 16.8713C19.021 16.6771 19.3379 16.3763 19.5518 16.0056C19.766 15.635 19.868 15.2103 19.8455 14.7829C19.823 14.3555 19.677 13.9438 19.4251 13.5978L18.5785 12.4344C18.277 12.0171 18.1159 11.5148 18.1185 11C18.1184 10.4866 18.281 9.98635 18.5829 9.57111L19.4296 8.40778C19.6814 8.06175 19.8275 7.65007 19.85 7.22267C19.8725 6.79528 19.7704 6.37054 19.5562 6C19.3423 5.62923 19.0255 5.32849 18.644 5.13423C18.2626 4.93997 17.833 4.86053 17.4074 4.90556L15.9774 5.05778C15.4667 5.11141 14.9521 5.00212 14.5074 4.74556C14.0645 4.48825 13.7144 4.09736 13.5074 3.62889L12.9185 2.31444C12.7447 1.92317 12.4612 1.59072 12.1022 1.3574C11.7433 1.12408 11.3244 0.99993 10.8962 1C10.4681 0.99993 10.0492 1.12408 9.69024 1.3574C9.3313 1.59072 9.04776 1.92317 8.87402 2.31444L8.28957 3.62889C8.0825 4.09736 7.73245 4.48825 7.28957 4.74556C6.84479 5.00212 6.33024 5.11141 5.81957 5.05778L4.38513 4.90556C3.95946 4.86053 3.52987 4.93997 3.14844 5.13423C2.76702 5.32849 2.45014 5.62923 2.23624 6C2.02206 6.37054 1.92002 6.79528 1.94251 7.22267C1.96499 7.65007 2.11103 8.06175 2.36291 8.40778L3.20957 9.57111C3.51151 9.98635 3.67411 10.4866 3.67402 11C3.67411 11.5134 3.51151 12.0137 3.20957 12.4289L2.36291 13.5922C2.11103 13.9382 1.96499 14.3499 1.94251 14.7773C1.92002 15.2047 2.02206 15.6295 2.23624 16C2.45036 16.3706 2.76727 16.6712 3.14864 16.8654C3.53001 17.0596 3.95949 17.1392 4.38513 17.0944L5.81513 16.9422C6.3258 16.8886 6.84034 16.9979 7.28513 17.2544C7.72966 17.511 8.08134 17.902 8.28957 18.3711Z"
                    stroke="#7F56D9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div className="drop-content">
                <p>Account Settings</p>
                <p className="desc">Update your personal details.</p>
              </div>
            </div>

            {/* <FormattedMessage id="TopbarDesktop.accountSettingsLink" /> */}
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <Link
            to={{ pathname: 'https://forms.gle/eR2gQjuroDTwGhn58' }}
            target="_blank"
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
          >
            <div className="menu-drop-container">
              <span className="icon-menu">
                <svg
                  fill="#7F56D9"
                  height="22px"
                  width="21px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 295.021 295.021"
                  style={{ fill: '#7F56D9' }}
                >
                  <g>
                    <path
                      d="M293.037,12.394c-1.888-2.051-4.762-2.882-7.449-2.166l-236,62.954c-2.591,0.691-4.615,2.712-5.311,5.302
		c-0.695,2.589,0.044,5.352,1.939,7.248l7.132,7.134l-29.187,29.188c-2.929,2.93-2.929,7.678,0,10.607
		c1.465,1.464,3.385,2.196,5.304,2.196c1.919,0,3.839-0.732,5.304-2.196l29.186-29.186l15.059,15.063l-39.878,39.878
		c-2.929,2.93-2.929,7.678,0,10.607c1.465,1.464,3.385,2.196,5.304,2.196c1.919,0,3.839-0.732,5.304-2.196l39.876-39.876
		l1.603,1.603v26.915l-69.163,69.163c-2.929,2.93-2.929,7.678,0,10.607c1.465,1.464,3.385,2.196,5.304,2.196
		c1.919,0,3.839-0.732,5.304-2.196l58.556-58.556v30.155l-42.071,41.981c-2.932,2.926-2.937,7.674-0.011,10.606
		c1.465,1.468,3.387,2.202,5.309,2.202c1.917,0,3.834-0.731,5.298-2.191l34.021-33.949c1.374,1.212,3.14,1.889,4.96,1.889
		c1.127,0,2.272-0.256,3.349-0.794l41.419-20.715l-41.696,41.696c-2.929,2.93-2.929,7.678,0,10.607
		c1.465,1.464,3.385,2.196,5.304,2.196c1.919,0,3.839-0.733,5.304-2.196l54.205-54.205l15.059,15.063l-30.86,30.86
		c-2.929,2.93-2.929,7.678,0,10.607c1.465,1.464,3.385,2.196,5.304,2.196c1.919,0,3.839-0.733,5.304-2.196l30.858-30.858
		l11.274,11.277c1.422,1.423,3.336,2.197,5.304,2.197c0.548,0,1.1-0.06,1.646-0.183c2.509-0.564,4.553-2.374,5.418-4.796
		l78.668-220.334C295.52,17.373,294.926,14.445,293.037,12.394z M235.601,39.088L99.936,118.251L66.019,84.324L235.601,39.088z
		 M132.241,171.782l16.754,16.76l-33.506,16.757L132.241,171.782z M125.168,153.545c-0.697,0.628-1.271,1.382-1.69,2.221
		l-17.257,34.526v-58.343l122.027-71.204L125.168,153.545z M205.775,224.123l-64.697-64.718L270.475,42.914L205.775,224.123z"
                    />
                    <path
                      d="M27.35,183.636c0-1.971-0.8-3.91-2.2-5.301c-1.39-1.399-3.319-2.199-5.3-2.199c-1.97,0-3.91,0.8-5.3,2.199
		c-1.4,1.391-2.2,3.33-2.2,5.301c0,1.979,0.8,3.909,2.2,5.3c1.39,1.399,3.33,2.2,5.3,2.2c1.981,0,3.91-0.801,5.3-2.2
		C26.55,187.545,27.35,185.615,27.35,183.636z"
                    />
                    <path
                      d="M90.33,269.005c-1.981,0-3.91,0.801-5.3,2.19c-1.4,1.4-2.2,3.34-2.2,5.31c0,1.971,0.8,3.9,2.2,5.301
		c1.39,1.399,3.319,2.199,5.3,2.199c1.97,0,3.91-0.8,5.3-2.199c1.4-1.4,2.2-3.33,2.2-5.301c0-1.97-0.8-3.909-2.2-5.31
		C94.24,269.806,92.31,269.005,90.33,269.005z"
                    />
                    <path
                      d="M140.81,265.186c-1.97,0-3.899,0.8-5.3,2.2c-1.399,1.399-2.2,3.33-2.2,5.3c0,1.979,0.801,3.91,2.2,5.31
		c1.39,1.391,3.33,2.19,5.3,2.19c1.981,0,3.91-0.8,5.311-2.19c1.39-1.399,2.189-3.33,2.189-5.31c0-1.97-0.8-3.9-2.189-5.3
		C144.72,265.985,142.79,265.186,140.81,265.186z"
                    />
                    <path
                      d="M189.939,255.105c-1.97,0-3.909,0.81-5.3,2.2c-1.4,1.399-2.2,3.33-2.2,5.3c0,1.98,0.801,3.91,2.2,5.31
		c1.4,1.4,3.33,2.2,5.3,2.2c1.971,0,3.9-0.8,5.301-2.2c1.4-1.399,2.199-3.33,2.199-5.31c0-1.97-0.8-3.9-2.199-5.3
		C193.85,255.915,191.91,255.105,189.939,255.105z"
                    />
                    <path
                      d="M33.229,270.045c-1.97,0-3.909,0.801-5.3,2.19c-1.399,1.39-2.2,3.33-2.2,5.3c0,1.98,0.801,3.91,2.2,5.31
		c1.391,1.39,3.33,2.199,5.3,2.199c1.98,0,3.91-0.81,5.311-2.199c1.39-1.4,2.189-3.33,2.189-5.31c0-1.97-0.8-3.91-2.189-5.3
		C37.14,270.846,35.21,270.045,33.229,270.045z"
                    />
                    <path
                      d="M15,149.895c0-1.97-0.8-3.9-2.2-5.3c-1.39-1.4-3.32-2.2-5.3-2.2c-1.981,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.319-2.2,5.3
		c0,1.98,0.8,3.91,2.2,5.3c1.39,1.4,3.33,2.2,5.3,2.2s3.899-0.8,5.3-2.2C14.2,153.806,15,151.865,15,149.895z"
                    />
                    <path
                      d="M27.37,98.895c1.97,0,3.899-0.8,5.3-2.2c1.39-1.39,2.2-3.33,2.2-5.3c0-1.97-0.811-3.91-2.2-5.3c-1.4-1.4-3.33-2.2-5.3-2.2
		c-1.98,0-3.91,0.8-5.311,2.2c-1.39,1.39-2.189,3.33-2.189,5.3c0,1.97,0.8,3.91,2.189,5.3C23.46,98.096,25.39,98.895,27.37,98.895z"
                    />
                  </g>
                </svg>
              </span>
              <div className="drop-content">
                <p>Launch in your City</p>
                {/* <p className="desc">Update your personal details.</p> */}
              </div>
            </div>

            {/* <FormattedMessage id="TopbarDesktop.accountSettingsLink" /> */}
          </Link>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            Log Out
            {/* <FormattedMessage id="TopbarDesktop.logout" /> */}
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup} style={{ margin: 0 }}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const listingLink =
    authenticatedOnClientSide && currentUserListingFetched && currentUserListing ? (
      <ListingLink
        className={css.createListingLink}
        listing={currentUserListing}
        children={
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.viewListing" />
          </span>
        }
      />
    ) : null;

  const createListingLink =
    isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing) ? null : (
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    );

  const HeaderSearch = () =>
    authenticatedOnClientSide && (
      <div className="headerSearch">
        <TopbarSearchFormHome
          className={topBarSearchCss.searchLink}
          desktopInputRoot={topBarSearchCss.topbarSearchWithLeftPadding}
          onSubmit={handleSubmit}
          setSelected={setSelectedListOption}
          selectedList={selectedList}
          selectedServiceList={selectedServiceList}
          setSelectedService={setSelectedService}
        />
      </div>
    );
  const [maybeLater, setMayBeLater] = useState(false);
  const userType = currentUser?.attributes?.profile?.publicData?.usertype;
  let step2 = {};
  if (userType === 'provider') {
    step2 = {
      title: `Nice Job, ${currentUser?.attributes?.profile?.displayName}!`,
      desc: `We recommend you move on to create a listing which allows churches to find the services you provide on the WorshipService platform.`,
      actionImg: done,
      actionBtnText: `Create Your Listing`,
      actionBtnHandler: () => {
        props.history.push('/l/new');
        localStorage.setItem('onBoardingStep', 'done');
      },
      showAbort: true,
      abortBtnText: 'Maybe Later',
      abortBtnHandler: () => {
        setMayBeLater(true);
        localStorage.setItem('onBoardingStep', 'done');
      },
    };

    if (currentUserHasListings) {
      localStorage.setItem('onBoardingStep', 'done');
    }
  }

  if (userType === 'customer') {
    step2 = {
      title: `Nice Job, ${currentUser?.attributes?.profile?.displayName}!`,
      desc: `We recommend you move on to create a listing which allows churches to find the services you provide on the WorshipService platform.`,
      actionImg: done,
      actionBtnText: `Start Searching`,
      actionBtnHandler: () => {
        setMayBeLater(true);
        props.history.push('/s');
        localStorage.setItem('onBoardingStep', 'done');
      },
      showAbort: false,
      abortBtnText: '',
      abortBtnHandler: () => {},
    };
  }

  const OnBoardingDetailMap = {
    step1: {
      title: `Let’s Complete Your Profile`,
      desc: ``,
      video: 'https://youtu.be/YvLJ0i3MnJs',
      actionImg: founder,
      actionBtnText:
        userType === 'customer' ? `Add credit card info` : 'Set up your payout details',
      actionBtnHandler: () => {
        if (userType === 'customer') {
          localStorage.setItem('onBoardingStep', 'done');
          window.location = `/profile-settings?ct_type=church`;
        } else {
          localStorage.setItem('onBoardingStep', 'done');
          window.location = `/profile-settings?ct_type=musician`;
        }
        localStorage.setItem('onBoardingStep', 'done');
      },
      showAbort: false,
      abortBtnText: '',
      abortBtnHandler: () => {},
    },
    step2,
  };
  const currentStep = localStorage.getItem('onBoardingStep') || '';

  let hasProperty = OnBoardingDetailMap[currentStep]
    ? Object.keys(OnBoardingDetailMap[currentStep]).length
    : false;

  return (
    <nav className={classes}>
      {isAuthenticated && currentStep && currentStep !== 'done' && hasProperty && (
        <OnBoardingModal details={OnBoardingDetailMap[currentStep]} />
      )}

      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {authenticatedOnClientSide && (
        <div className="headerSearch">
          <TopbarSearchFormHome
            className={topBarSearchCss.searchLink}
            desktopInputRoot={topBarSearchCss.topbarSearchWithLeftPadding}
            onSubmit={handleSubmit}
            setSelected={setSelectedListOption}
            selectedList={selectedList}
            selectedServiceList={selectedServiceList}
            setSelectedService={setSelectedService}
          />
        </div>
      )}
      {/* {search} */}
      {/* <Link
        className={css.createListingLink}
        to={{ pathname: 'https://forms.gle/RouhMv7HqqpY2GUa7' }}
        target="_blank"
      >
        <span className={css.createListing}>Contact Us</span>
      </Link> */}
      {/* <NamedLink
        className={css.createListingLink}
        name="InboxPage"
        params={{ tab: 'share' }}
        title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
      >
        <CardGiftcardIcon />
        <span className={css.createListing}> Rewards</span>
      </NamedLink> */}

      {/* <NamedLink className={css.createListingLink} name="ManageListingsPage">
        <span className={css.createListing}>
          <FormattedMessage id="ManageListingsPage.manage" />
        </span>
      </NamedLink> */}

      {/* <NamedLink name="AboutPage" className={css.signupLink}>
        <span className={css.signup}>
          <FormattedMessage id="About" />
        </span>
      </NamedLink> */}

      {/* {listingLink}
      {createListingLink}
      {inboxLink}
      {profileMenu} */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link
          className={`${css.createListingLink} contactus`}
          to={{ pathname: 'https://jobs.worshipserve.com/' }}
          target="_blank"
        >
          <span className={css.createListing}>Job Board</span>
        </Link>
        {loginLink}
        {signupLink}
        {inboxLink}
        {profileMenu}
      </div>
    </nav>
  );
};

TopbarDesktopComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktopComponent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarDesktop = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TopbarDesktopComponent);

export default TopbarDesktop;
