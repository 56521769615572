import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import css from './SectionHero.module.css';
import HeroAvatar from '../../assets/Hero-avatar.png';

const SectionHero = props => {
  const { rootClassName, className, search } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={`${css.heroContent} topbar_search_hero`}>
        <h1 className={css.heroMainTitle}>
          {/* <FormattedMessage id="SectionHero.title" /> */}
          Find and book worship leaders, musicians and sound engineers.
        </h1>
        {/* <h2 className={css.heroSubTitle}>
         
        </h2> */}
        <div style={{position:"relative"}}>
          <img src={HeroAvatar} alt="HeroAvatar" className={css.heroAvatar} />
          <div className={css.searchContainer}>
            <h2>Need help? Call 919-480-3839</h2>
            {search}

            {/* <div className={css.musicStyles}>
            <p className={css.musicStyleItem}>Worship Leaders</p>
            <p className={css.musicStyleItem}>Guitarists</p>
            <p className={css.musicStyleItem}>Drummers</p>
            <p className={css.musicStyleItem}>Bassists</p>
            <p className={css.musicStyleItem}>Keys</p>
            <p className={css.musicStyleItem}>Vocalists</p>
            <p className={css.musicStyleItem}>Audio Techs</p>
            <p className={css.musicStyleItem}>+ More</p>
          </div> */}
          </div>
        </div>
        {/* <br /> */}
        {/* <NamedLink
          name="SearchPage"
          to={{
            search:
              'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
          }}
          className={css.heroButton}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink> */}
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
