import React from 'react';
import css from './Pagination.module.css';

const Pagination = () => {
  return (
    <div className={css.paginationContainer}>
      <div>
        <svg
          style={{ marginRight: 10 }}
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8334 7.35311H1.16669M1.16669 7.35311L7.00002 13.1864M1.16669 7.35311L7.00002 1.51978"
            stroke="#667085"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span>Previous</span>
      </div>

      <div>
        <span className={css.paginationLink + ' ' + css.active}>1</span>
        <span className={css.paginationLink}>2</span>
        <span className={css.paginationLink}>3</span>
        <span className={css.paginationLink}>4</span>

      </div>

      <div>
        <span>Next</span>

        <svg
          style={{ marginLeft: 10 }}
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16666 7.35311H12.8333M12.8333 7.35311L6.99999 1.51978M12.8333 7.35311L6.99999 13.1864"
            stroke="#667085"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default Pagination;
