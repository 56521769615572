import React from 'react';

const Stripe = () => {
  return (
    <svg width="65" height="27" viewBox="0 0 65 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.4223 5.68089C42.6605 5.68089 41.5279 6.50786 40.8987 7.08314L40.665 5.96853H36.71V26.9303L41.2043 25.9775L41.2223 20.8899C41.8695 21.3573 42.8223 22.0225 44.4043 22.0225C47.6223 22.0225 50.5527 19.4337 50.5527 13.7348C50.5347 8.52134 47.5684 5.68089 44.4223 5.68089ZM43.3437 18.0674C42.283 18.0674 41.6538 17.6899 41.2223 17.2225L41.2043 10.5528C41.6718 10.0315 42.3189 9.67191 43.3437 9.67191C44.9796 9.67191 46.1122 11.5056 46.1122 13.8607C46.1122 16.2697 44.9976 18.0674 43.3437 18.0674Z"
        fill="#635BFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.5259 4.62022L35.0382 3.64944V0L30.5259 0.952809V4.62022Z"
        fill="#635BFF"
      />
      <path d="M35.0382 5.98651H30.5259V21.7168H35.0382V5.98651Z" fill="#635BFF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.6898 7.31685L25.4022 5.98652H21.519V21.7169H26.0134V11.0562C27.0741 9.67191 28.8719 9.92359 29.4292 10.1213V5.98652C28.8539 5.77079 26.7505 5.37528 25.6898 7.31685Z"
        fill="#635BFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7009 2.0854L12.3144 3.02023L12.2964 17.4202C12.2964 20.0809 14.2919 22.0405 16.9526 22.0405C18.4267 22.0405 19.5054 21.7708 20.0986 21.4472V17.7978C19.5234 18.0315 16.6829 18.8584 16.6829 16.1978V9.81573H20.0986V5.98652H16.6829L16.7009 2.0854Z"
        fill="#635BFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.7191 13.9146C64.7191 9.31237 62.4899 5.68091 58.2292 5.68091C53.9506 5.68091 51.3618 9.31237 51.3618 13.8787C51.3618 19.2899 54.418 22.0225 58.8045 22.0225C60.9438 22.0225 62.5618 21.5371 63.7843 20.8539V17.2584C62.5618 17.8697 61.1596 18.2472 59.3798 18.2472C57.636 18.2472 56.0899 17.636 55.8922 15.5146H64.6832C64.6832 15.2809 64.7191 14.3461 64.7191 13.9146ZM55.8382 12.2068C55.8382 10.1753 57.0787 9.33035 58.2113 9.33035C59.3079 9.33035 60.4764 10.1753 60.4764 12.2068H55.8382Z"
        fill="#635BFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.54832 10.5528C4.54832 9.85169 5.1236 9.58203 6.07641 9.58203C7.4427 9.58203 9.16854 9.99551 10.5348 10.7326V6.50787C9.0427 5.91462 7.56854 5.68091 6.07641 5.68091C2.42697 5.68091 0 7.58653 0 10.7685C0 15.7303 6.83146 14.9393 6.83146 17.0787C6.83146 17.9056 6.11236 18.1753 5.10562 18.1753C3.61348 18.1753 1.70786 17.5641 0.197753 16.7371V21.0157C1.86966 21.7348 3.55955 22.0405 5.10562 22.0405C8.84494 22.0405 11.4157 20.1888 11.4157 16.9708C11.3978 11.6135 4.54832 12.5663 4.54832 10.5528Z"
        fill="#635BFF"
      />
    </svg>
  );
};

export default Stripe;
