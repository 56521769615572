import React, { useState, Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import css from '../../containers/SearchPage/SearchPage.module.css';
import './custom.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { isMobile } from 'react-device-detect';
import ListingCardMobile from '../ListingCard/ListingCardMobile';
import { NamedLink, ResponsiveImage } from '../../components';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import _ from 'lodash';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close';
import SingleListCardContainer from '../../containers/SingleListCardContainer/SingleListCardContainer';
import Pagination from '../Pagination/Pagination';
import Heart from '../Heart/Heart';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    getSortAllData,
    currentUser,
    totalSortingData,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [isAvatarOnline, setIsAvatarOnline] = useState(true);
  const [showmobile, setshowiMobile] = useState(false);
  const [faviouritePeople, setFaviouritePeople] = useState([]);
  const [listingNewData, setListingNewData] = useState({ data: [], count: 0 });
  const [isFavorite, setFavorite] = useState(false);
  function paginationLinks(paginationDetails) {
    return paginationDetails && paginationDetails.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={paginationDetails}
        resetShowingData={props?.resetShowingData}
      />
    ) : null;
  }

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  let available_list = 0;
  const CHECK_URL = search?.pub_yogaStyles ? search?.pub_yogaStyles?.split(',') : [];

  useEffect(() => {
    setFaviouritePeople(props?.favUserDetail);
  }, [props?.favUserDetail]);

  useEffect(() => {
    if (CHECK_URL?.length > 0) {
      const DATA = _.filter(listings, row => {
        if ('servicePrice' in row?.attributes?.publicData) {
          return row;
        }
      });
      console.log('value check for listing', DATA);
      setListingNewData(prv => ({ data: DATA, count: DATA.length }));
    } else {
      setListingNewData(prv => ({ data: listings, count: listings.length }));
    }
  }, [listings, search?.pub_yogaStyles]);
  let isSearchedOrNot = search.pub_yogaStyles || search.keywords || search.date;
  let perPage = isSearchedOrNot ? listings.length : 8;
  let searchPage = search.page || 1;
  console.log('search', search);
  const getPaginationDetail = data => {
    let totalPages = Math.ceil(data.length / perPage);
    let totalItems = data.length;
    let filteredArr = [...data];
    const from = searchPage === 1 ? 0 : searchPage * perPage - perPage;
    const to = searchPage * perPage;
    let filterData = filteredArr.slice(from, to);

    return {
      pagination: {
        totalPages,
        totalItems,
        perPage,
        paginationLimit: totalPages,
        page: searchPage,
      },
      filteredArr: filterData,
    };
  };

  const filterResults = () => {
    const favoriteListings = currentUser?.attributes?.profile?.publicData?.favoriteListings;
    let searchParams = search;
    // let isParams = Object.keys(searchParams).length;
    let favArr = [];
    let mapResults = [];

    if (isFavorite) {
      listings?.map((val, ind) => {
        if (favoriteListings?.indexOf(val.id.uuid) > -1) {
          val['isFavorite'] = true;
          favArr.push(val);
        } else {
          console.log('from map', val);
          mapResults.push(val);
        }
      });
    } else {
      mapResults = listings;
    }
    console.log('mapResults ***', listings);
    let searchResults = [];
    let isSearched = false;

    if (searchParams?.keywords || searchParams?.pub_yogaStyles) {
      let city = searchParams?.keywords;
      let service = searchParams?.pub_yogaStyles?.replace('has_any:', '');
      console.log(service);
      isSearched = true;
      searchResults = [...listings];

      // mapResults?.map((val, ind) => {
      //   const publicData = val?.attributes?.publicData;

      //   if (city && service ) {
      //     if (publicData?.city?.includes(city) && publicData?.yogaStyles?.includes(service)) {
      //       searchResults = [...searchResults, val];
      //     }
      //   } else if (city ) {
      //     if (publicData?.city?.includes(city)) {
      //       searchResults = [...searchResults, val];
      //     }
      //   } else if (service) {
      //     if (publicData?.yogaStyles?.includes(service)) {
      //       searchResults = [...searchResults, val];
      //     }
      //   }
      // });
    }
    let filteredData = isSearched ? searchResults : mapResults;

    let paginationDetails = getPaginationDetail([...favArr, ...filteredData]);
    console.log('paginationDetails', paginationDetails);
    return {
      paginationDetails: paginationDetails.pagination,
      results: paginationDetails.filteredArr,
    };
  };

  const { paginationDetails, results } = filterResults();
  return (
    <div className={css.adjustFromTop + ' searchPage'}>
      <div className={css.searchFilter}>
        <div>{/* <p>Search Results 16/80</p> */}</div>
        {/* <div>
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: 10 }}
          >
            <path
              d="M1.5 3.6665L11.5 3.6665M11.5 3.6665C11.5 5.04722 12.6193 6.1665 14 6.1665C15.3807 6.1665 16.5 5.04722 16.5 3.6665C16.5 2.28579 15.3807 1.1665 14 1.1665C12.6193 1.1665 11.5 2.28579 11.5 3.6665ZM6.5 10.3332L16.5 10.3332M6.5 10.3332C6.5 11.7139 5.38071 12.8332 4 12.8332C2.61929 12.8332 1.5 11.7139 1.5 10.3332C1.5 8.95246 2.61929 7.83317 4 7.83317C5.38071 7.83317 6.5 8.95246 6.5 10.3332Z"
              stroke="#6941C6"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span style={{ color: '#6941C6' }}>Filter</span>
        </div> */}
      </div>
      {props.isuserLogedin && (
        <button className="likedProfiles" onClick={() => setFavorite(!isFavorite)}>
          <Heart isFilled={isFavorite} /> Favorite
        </button>
      )}
      <SingleListCardContainer
        searchParams={props.search}
        selected_city={props?.selected_city}
        listings={results}
        getSortAllData={getSortAllData}
      />
      <p>{paginationLinks(paginationDetails)}</p>

      {/* <Pagination /> */}
    </div>
  );
  // return (
  //   <div className={classes}>
  //     <div className={`${css.listingCards} custom_listing_css`}>

  //       {(listings)?.map(l => {
  //         function userExists(id) {
  //           console.log('currentListing', l.attributes.publicData?.city);
  //           const defaultseletedCity = [];
  //           const citydata = l.attributes.publicData?.city?.split(',');
  //           citydata?.map(cityd => {
  //             defaultseletedCity.push({ name: cityd, id: cityd });
  //           });
  //           return defaultseletedCity?.some(function (el) {
  //             return el.id == id;
  //           });
  //           // return l.author.attributes.profile.publicData?.city?.some(function(el) {
  //           //   return el.id == id;
  //           // });
  //         }

  //         let intersection = props?.selected_city
  //           ? props?.selected_city?.filter(element => userExists(element))
  //           : [];
  //         let selectedCity = props?.selected_city?.length > 0 ? props?.selected_city : [];

  //         if (selectedCity?.length > 0 && intersection.length > 0) {
  //           available_list = available_list + 1;

  //           return ('22'
  //             // <ListingCard
  //             //   className={`${css.listingCard} custom_listing_css_child`}
  //             //   key={l.id.uuid}
  //             //   listing={l}
  //             //   allData={listings}
  //             //   renderSizes={cardRenderSizes}
  //             //   setActiveListing={setActiveListing}
  //             //   data_panel={CHECK_URL}
  //             //   available_list={available_list}
  //             //   getSortAllData={getSortAllData}
  //             //   totalSortingData={totalSortingData}
  //             //   currentUser={props.currentUser}
  //             //   fetchCurrentUser={() => props?.fetchCurrentUser()}
  //             // />
  //           );
  //         } else if (selectedCity?.length == 0) {
  //           available_list = available_list + 1;

  //           return ('hh'
  //             // <ListingCard
  //             //   className={`${css.listingCard} custom_listing_css_child`}
  //             //   key={l.id.uuid}
  //             //   listing={l}
  //             //   allData={listings}
  //             //   renderSizes={cardRenderSizes}
  //             //   setActiveListing={setActiveListing}
  //             //   data_panel={CHECK_URL}
  //             //   available_list={available_list}
  //             //   getSortAllData={getSortAllData}
  //             //   totalSortingData={totalSortingData}
  //             //   currentUser={props.currentUser}
  //             //   fetchCurrentUser={() => props?.fetchCurrentUser()}
  //             // />
  //           );
  //         } else {
  //           return null;
  //         }
  //       })}
  //       {available_list === 0 && <h1 className="serch_result_not_found"> Result not found</h1>}
  //       {props.children}
  //     </div>
  //     {props.isuserLogedin && isMobile ? (
  //       <>
  //         {/* {!showmobile && (
  //           <div className="button">
  //             <button
  //               className="wish_button"
  //               onClick={() => {
  //                 setshowiMobile(!showmobile);
  //               }}
  //             >
  //               <FavoriteIcon />
  //               Favorite list
  //             </button>
  //           </div>
  //         )} */}
  //         {showmobile && (
  //           <div className={`Favorites ${showmobile ? 'mobilefav' : ''}`}>
  //             <Typography variant="h6" className="Favorites_title">
  //               Favorites
  //             </Typography>
  //             <div className="cross_icons">
  //               <CloseIcon
  //                 onClick={() => {
  //                   setshowiMobile(!showmobile);
  //                 }}
  //               />
  //             </div>
  //             <List>
  //               {faviouritePeople?.length > 0 &&
  //                 _.map(faviouritePeople, ({ id, slug, image, name }) => {
  //                   return (
  //                     <NamedLink
  //                       className="favourite_section"
  //                       name="ListingPage"
  //                       params={{ id, slug }}
  //                     >
  //                       <ListItem>
  //                         <ListItemAvatar>
  //                           <Avatar className={isAvatarOnline ? 'avatar_online' : 'avatar'}>
  //                             <img
  //                               src={image?.attributes?.variants?.['landscape-crop']?.url}
  //                               height={40}
  //                               width={40}
  //                             />
  //                           </Avatar>
  //                         </ListItemAvatar>
  //                         <ListItemText primary={name} />
  //                       </ListItem>
  //                     </NamedLink>
  //                   );
  //                 })}
  //             </List>
  //           </div>
  //         )}
  //       </>
  //     ) : props.isuserLogedin ? (
  //       <div className={`Favorites ${showmobile ? 'mobilefav' : ''}`}>
  //         <Typography variant="h6" className="Favorites_title">
  //           Favorites
  //         </Typography>
  //         <List>
  //           {faviouritePeople?.length > 0 &&
  //             _.map(faviouritePeople, ({ id, slug, image, name }) => {
  //               return (
  //                 <NamedLink className="favourite_section" name="ListingPage" params={{ id, slug }}>
  //                   <ListItem>
  //                     <ListItemAvatar>
  //                       <Avatar className={isAvatarOnline ? 'avatar_online' : 'avatar'}>
  //                         <img
  //                           src={image?.attributes?.variants?.['landscape-crop']?.url}
  //                           height={40}
  //                           width={40}
  //                         />
  //                       </Avatar>
  //                     </ListItemAvatar>
  //                     <ListItemText primary={name} />
  //                   </ListItem>
  //                 </NamedLink>
  //               );
  //             })}
  //         </List>
  //       </div>
  //     ) : null}
  //     {/* {(listingNewData?.count >= 24) ? paginationLinks(listingNewData) : ''} */}
  //     {paginationLinks()}
  //   </div>
  // );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
