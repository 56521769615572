import pick from 'lodash/pick';
import config from '../../config';
import { initiatePrivileged, transitionPrivileged, initiatePrivilegedTest } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { timestampToDate } from '../../util/dates';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../../src/util/sdkLoader';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import dotenv from 'dotenv';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());
  console.log('orderParams,transactionId-----------------------------', orderParams, transactionId);
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    ...orderParams.bookingData,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: orderParams,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params: orderParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };
  console.log('isTransition', isTransition);
  console.log('isPrivilegedTransition', isPrivilegedTransition);
  // return
  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    console.log('here is here',orderParams)
    // return
    const requests = bookingData.totalSelectedDatesTimes.map((date, ind) => ({
      isSpeculative: false,
      bookingData: {
        ...bookingData,
        bookingStartDate: date.bookingStartDate,
        bookingEndDate: date.bookingEndDate,
        startDate: date.bookingStartDate,
        endDate: date.bookingEndDate,
        reherical_date:
          ind === 0
            ? { ...orderParams?.bookingData?.reherical_date }
            : null,
      },
      bodyParams: {
        ...bodyParams,
        params: {
          ...orderParams,
          bookingData: {
            ...bookingData,
            bookingStartDate: date.bookingStartDate,
            bookingEndDate: date.bookingEndDate,
          },
          bookingStart: timestampToDate(date.startTimeStamp),
          bookingEnd: timestampToDate(date.endTimeStamp),
        },
      },
      queryParams,
    }));

    console.log('requests--------->', requests);

    // return
    console.log('{ isSpeculative: false, bookingData, bodyParams, queryParams }', {
      isSpeculative: false,
      bookingData,
      bodyParams,
      queryParams,
    });

    // Array to store all the promises
    return requests.map(request =>
      initiatePrivilegedTest(request)
        .then(handleSucces)
        .catch(handleError)
    );

    // Loop through each request and call initiatePrivileged
    // requests.forEach(request => {
    //   const promise = initiatePrivilegedTest(request)
    //   .then(handleSucces)
    //   .catch(handleError);
    //   promises.push(promise);
    // });

    // You can do something when all promises are resolved using Promise.all
    // Promise.all(promises)
    //   .then(results => {
    //     // Handle results if needed
    //     console.log('resultsresultsresults',results)
    //   })
    //   .catch(error => {
    //     // Handle error if any of the promises fails
    //     console.log('errorerror',error)
    //   });

    // return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
    //   .then(handleSucces)
    //   .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

function formatAndJoinDates(dateArray) {
  const moment = require('moment');
  // Check if Moment.js is available
  if (typeof moment !== 'function') {
    console.error('Moment.js is not loaded!');
    return '';
  }

  // Map through the dateArray and format each date using Moment.js
  const formattedDates = dateArray.map(date => moment(date?.bookingStartDate).format('MM-DD-YYYY'));

  // Join the formatted dates with commas
  const joinedDates = formattedDates.join(', ');

  return joinedDates;
}

export const metadatafunction = orderParams => {
  const flexIntegrationSdk = require('sharetribe-flex-integration-sdk');
  const { UUID } = sdkTypes;
  const moment = require('moment');
  // for local
  // const integrationSdk = flexIntegrationSdk.createInstance({
  //   clientId: 'cfdc0399-b2f2-406a-8e2c-9295e430d0d2',
  //   clientSecret: '44fb1639aa855be94a12910affa69a5197ee6b85',
  // });
  // for live
  const integrationSdk = flexIntegrationSdk.createInstance({
    clientId: 'c6710684-4266-40c5-ab99-71687608df1b',
    clientSecret: '62e5937dbb886a234323dfec74476736ea951638',
  });

  const {
    bookingDates: { bookingStart },
    bookingData: {
      description,
      description_church,
      rehericaltime,
      reherical_date,
      other_details,
      services,
      ...rest
    },
  } = orderParams;
  let datesJoined = formatAndJoinDates(orderParams?.bookingData?.totalSelectedDatesTimes);
  // console.log('meta order', { orderParams, datesJoined });
  // return
  // const rehericalDate = rehericaltime
  //   ? {
  //       reherical: rehericaltime,
  //       reherical_date: moment(String(reherical_date?.startDate)).format('DD-MM-YYYY'),
  //       reherical_time: moment(String(reherical_date?.startDate)).format('hh:mm:ss a'),
  //     }
  //   : {
  //       reherical: rehericaltime,
  //     };

  return integrationSdk.transactions
    .updateMetadata(
      {
        id: new UUID(orderParams?.transactionId?.uuid),
        metadata: {
          date: datesJoined,
          time: moment(String(bookingStart)).format('hh:mm:ss a'),
          church: description_church,
          address: description,
          other_details: other_details,
          reherical_time: rest?.reherical_time,
          services: services,
          selected_service: orderParams?.bookingData?.selectedService,
          city: rest.city,
          state: rest.state,
          reherical_date: JSON.stringify(orderParams?.bookingData?.reherical_date),
          rehericaltime: orderParams?.bookingData?.rehericaltime,
          totalSelectedDate:
            JSON.stringify(orderParams?.bookingData?.totalSelectedDatesTimes) || '',
          // ...rehericalDate,
        },
      },
      {
        expand: true,
      }
    )
    .then(res => {
      // console.log('this is working', res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
};

export const sendMessageTo = messageData => {
  fetch(`/api/messages`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(messageData),
  })
    .then(res => res.json())
    .then(data => {
      if (data.success) {
      } else {
      }
    })
    .catch(error => {
      console.log('error', error);
    });
};

export const updateStripeCustomer = messageData => {
  return fetch(`/api/update_stripe_customer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(messageData),
  })
    .then(res => res.json())
    .then(data => {
      if (data.success) {
        console.log('stripe data here', data);
        return data;
      } else {
        return data;
      }
    })
    .catch(error => {
      console.log('error', error);
    });
};

export const confirmPayment = orderParams => async (dispatch, getState, sdk) => {
  //   const moment = require('moment');

  //   let date = moment(String(orderParams?.bookingData?.bookingStartDate?.date)).format(
  //     'MM-DD-YYYY'
  //   );
  //   let time = moment(String(orderParams?.bookingData?.bookingStartDate?.date)).format(
  //     'hh:mm:ss a'
  //   );
  //   let church_name = orderParams?.bookingData?.description_church;
  //   let provider_name = orderParams?.listing?.attributes.title;
  //   let provider_phone_number =
  //     orderParams?.listing?.author?.attributes.profile?.publicData?.phoneNumber;

  //   let customer_phone_number =
  //     orderParams?.currentUser?.attributes.profile?.publicData?.phoneNumber;
  //   let customer_name = orderParams?.currentUser?.attributes.profile?.displayName;

  //   // need to add two more data in metadata
  //   let many_service = orderParams?.bookingData?.services;
  //   let other_details = orderParams?.bookingData?.other_details;
  //   let reherical_time = orderParams?.bookingData?.reherical_time;
  //   let address = orderParams?.bookingData?.description;
  //   // "customer is still waiting for you to accept or decline the request. This request will automatically decline in 24 hours. Please accepted or declined as soon as possible here"
  //   const messageData = {
  //     to: provider_phone_number,
  //     id: orderParams?.transactionId?.uuid,
  //     messageData: `${customer_name} is still waiting for you to accept or decline the request. This request will automatically decline in 24 hours. Please accepted or declined as soon as possible here: https://support.villagehymns.com/sale/${orderParams?.transactionId?.uuid}/details`,
  //     // body: `${customer_name} ${church_name} requested to book you for ${date}  ${time}  Accept or decline here: https://support.villagehymns.com/sale/${orderParams?.transactionId?.uuid}/details`,
  //     body: `
  // ${customer_name} requested to book you for ${date}  ${time}
  // Here are the details about the service:

  // What is the name of your church? ${church_name}
  // What is the address of the church? ${address}
  // When is rehearsal time? ${reherical_time}
  // How many services? ${many_service}
  // Any other details you would like to include? ${other_details}

  // This request will automatically decline in 24 hours. Please accept or decline as soon as possible here: https://support.villagehymns.com/sale/${orderParams?.transactionId?.uuid}/details
  // `,
  //   };

  //   const messageDataCustomer = {
  //     to: customer_phone_number,
  //     body: `${provider_name}  was sent a request. We will notify you when they accept or decline`,
  //   };

  //   dispatch(confirmPaymentRequest());
  //   sendMessageTo(messageData);
  //   sendMessageTo(messageDataCustomer);
  //   const bodyParams = {
  //     id: orderParams.transactionId,
  //     transition: TRANSITION_CONFIRM_PAYMENT,
  //     params: {},
  //   };

  //   return sdk.transactions
  //     .transition(bodyParams)
  //     .then(response => {
  //       const order = response.data.data;
  //       if (response.status === 200) {
  //         metadatafunction(orderParams);
  //       }

  //       dispatch(confirmPaymentSuccess(order.id));
  //       return order;
  //     })
  //     .catch(e => {
  //       dispatch(confirmPaymentError(storableError(e)));
  //       const transactionIdMaybe = orderParams.transactionId
  //         ? { transactionId: orderParams.transactionId.uuid }
  //         : {};
  //       log.error(e, 'initiate-order-failed', {
  //         ...transactionIdMaybe,
  //       });
  //       throw e;
  //     });

  //       }
  console.log('orderParams ------- 34', orderParams);

  const stripe_data = {
    customer_id: orderParams?.stripeCustomer?.attributes?.stripeCustomerId,
    email: orderParams?.currentUser?.attributes?.email,
  };
  console.log('orderParams ------- 34', stripe_data);
  await updateStripeCustomer(stripe_data)
    .then(res => {
      console.log('res checkout', res);
      if (res) {
        const moment = require('moment');
        let date = moment(String(orderParams?.bookingData?.bookingStartDate?.date)).format(
          'MM-DD-YYYY'
        );
        let time = moment(String(orderParams?.bookingData?.bookingStartDate?.date)).format(
          'hh:mm:ss a'
        );
        let church_name = orderParams?.bookingData?.description_church;
        let provider_name = orderParams?.listing?.attributes.title;
        let provider_phone_number =
          orderParams?.listing?.author?.attributes.profile?.publicData?.phoneNumber;

        let customer_phone_number =
          orderParams?.currentUser?.attributes.profile?.publicData?.phoneNumber;
        let customer_name = orderParams?.currentUser?.attributes.profile?.displayName;

        // need to add two more data in metadata
        let many_service = orderParams?.bookingData?.services;
        let other_details = orderParams?.bookingData?.other_details;
        let reherical_time = orderParams?.bookingData?.reherical_time;
        let address = orderParams?.bookingData?.description;
        let totalSelectedDate = orderParams?.bookingData?.totalSelectedDatesTimes;
        const parseRehersal = orderParams?.bookingData?.reherical_date
          ? orderParams?.bookingData?.reherical_date
          : {};
        const rehersalTimeFormated = orderParams?.bookingData?.reherical_date?.startDate
          ? `${moment(parseRehersal?.startDate).format('MMM DD h:mm A')} - ${moment(
              parseRehersal?.endDate
            ).format('h:mm A')}`
          : '';
        console.log('parse msg', orderParams, parseRehersal, rehersalTimeFormated);

        // "customer is still waiting for you to accept or decline the request. This request will automatically decline in 24 hours. Please accepted or declined as soon as possible here"
        const messageData = {
          to: provider_phone_number,
          id: orderParams?.transactionId?.uuid,
          messageData: `${customer_name} is still waiting for you to accept or decline the request. This request will automatically decline in 24 hours. Please accepted or declined as soon as possible here: https://www.worshipserve.com/sale/${orderParams?.transactionId?.uuid}/details`,
          // body: `${customer_name} ${church_name} requested to book you for ${date}  ${time}  Accept or decline here: https://support.villagehymns.com/sale/${orderParams?.transactionId?.uuid}/details`,
          body: `
  ${customer_name} requested to book you for ${formatAndJoinDates(totalSelectedDate)}  ${moment(
            orderParams?.bookingData?.bookingStartDate
          ).format('hh:mm:ss a')}
  
  Here are the details about the service:
  What is the selected service? ${orderParams?.bookingData?.selectedService}
  What is the name of your church? ${church_name}
  What is the address of the church? ${address}
  When is rehearsal time? ${rehersalTimeFormated}
  How many services? ${many_service}
  Any other details you would like to include? ${other_details}
  
  This request will automatically decline in 24 hours. Please accept or decline as soon as possible here: https://www.worshipserve.com/sale/${
    orderParams?.transactionId?.uuid
  }/details
  `,
        };
        console.log('messageData', messageData);
        const messageDataCustomer = {
          to: customer_phone_number,
          body: `${provider_name}  was sent a request. We will notify you when they accept or decline`,
        };

        dispatch(confirmPaymentRequest());
        sendMessageTo(messageData);
        sendMessageTo(messageDataCustomer);
        const bodyParams = {
          id: orderParams.transactionId,
          transition: TRANSITION_CONFIRM_PAYMENT,
          params: {},
        };

        return sdk.transactions
          .transition(bodyParams)
          .then(response => {
            console.log('transactions', response);
            const order = response.data.data;
            if (response.status === 200) {
              metadatafunction(orderParams);
            }

            dispatch(confirmPaymentSuccess(order.id));
            return order;
          })
          .catch(e => {
            dispatch(confirmPaymentError(storableError(e)));
            const transactionIdMaybe = orderParams.transactionId
              ? { transactionId: orderParams.transactionId.uuid }
              : {};
            log.error(e, 'initiate-order-failed', {
              ...transactionIdMaybe,
            });
            throw e;
          });
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;
  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  console.log('orderParamsorderParamsorderParams', orderParams);
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    ...orderParams.bookingData,
  };
  // console.log('bookingData spec', bookingData);
  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  console.log('--------------------------------', isTransition, isPrivilegedTransition);
  // return
  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    // return
    return transitionPrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged

    const requests = bookingData.totalSelectedDatesTimes.map(date => ({
      isSpeculative: true,
      bookingData: {
        ...bookingData,
        bookingStartDate: date.bookingStartDate,
        bookingEndDate: date.bookingEndDate,
      },
      bodyParams: {
        ...bodyParams,
        params: {
          ...params,
          bookingData: {
            ...bookingData,
            bookingStartDate: date.bookingStartDate,
            bookingEndDate: date.bookingEndDate,
          },
        },
      },
      queryParams,
    }));

    console.log('requests--------->', requests);

    // return

    // Array to store all the promises
    const promises = [];

    // Loop through each request and call initiatePrivileged
    requests.forEach(request => {
      const promise = initiatePrivilegedTest(request)
        .then(handleSuccess)
        .catch(handleError);
      promises.push(promise);
    });

    // You can do something when all promises are resolved using Promise.all
    Promise.all(promises)
      .then(results => {
        // Handle results if needed
      })
      .catch(error => {
        // Handle error if any of the promises fails
      });

    // console.log("requests object--------->",  { isSpeculative: true, bookingData, bodyParams, queryParams })

    // return initiatePrivilegedTest({ isSpeculative: true, bookingData, bodyParams, queryParams })
    //   .then(handleSuccess)
    //   .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
