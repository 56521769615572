import React, { Component, useEffect, useState } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, IconSpinner, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import EstimatedBreakdownMaybeReherisal from './EstimatedBreakdownMaybeReherisal';

import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import FieldDateAndTimeInputReherisal from './FieldDateAndTimeInputReherisal';

import { FieldTextInput, FieldCheckbox } from '../../components';

import css from './BookingTimeForm.module.css';
import { InlineTextButton } from '../../components';
import { Box, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
import BookingPopup from './BookingPopup';
import _ from 'lodash';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    // this.durationFunction = this.durationFunction(this);
    this.state = {
      rehericaltime: false,
      durationtime: 0,
      rehericalData: null,
      firstPayment: {},
      disabledButton: true,
      allFields: {
        description: '',
        description_church: '',
        other_details: '',
        reherical_time: '',
        services: '',
      },
    };
  }

  handleFormSubmit(e) {
    e.rehericaltime = this.state.rehericaltime;
    if (this.state.rehericaltime) {
      e.reherical_date = this.state.rehericalData;
    } else {
      e.reherical_date = null;
    }
    e = { ...e, ...this.state.allFields };
    e.duration = this?.state?.durationtime ? this.state.durationtime : 0;
    this.props.onSubmit(e);
  }

  showFormData() {
    this.setState({
      rehericaltime: !this.state.rehericaltime,
      durationtime: !this.state.rehericaltime ? this.state.durationtime : 0,
    });
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues, timeZone, unitType) {
    const { bookingStartTime, bookingEndTime } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;
    if (bookingStartTime && bookingEndTime) {
      this.setState({ disabledButton: false });
    }
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    this.setState({ firstPayment: { startDate, endDate, unitType, timeZone } });
    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate },
        listingId,
        isOwnListing,
        form: 'first',
      });
    }
  }

  durationFunction(bookingData) {
    let duration = '';
    let unit_price = this.props.lineItems && this.props.lineItems[0]?.unitPrice?.amount / 100;
    let reherisal_price = 0;

    if (bookingData?.endDate1) {
      const moment = require('moment');
      duration = moment
        .utc(
          moment(bookingData.endDate1, 'DD/MM/YYYY HH:mm:ss').diff(
            moment(bookingData.startDate1, 'DD/MM/YYYY HH:mm:ss')
          )
        )
        .format('HH:mm');
      function convertH2M(timeInHour) {
        var timeParts = timeInHour.split(':');
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
      }

      duration = convertH2M(duration);

      if (this.state.durationtime !== duration) {
        this.setState({
          durationtime: duration,
        });
      }
      duration = duration / 60;
      reherisal_price = duration * unit_price;
    } else {
      duration = 0;
    }
  }

  handleOnChange2(formValues) {
    this.setState({
      allFields: {
        description: formValues?.values?.description,
        description_church: formValues?.values?.description_church,
        other_details: formValues?.values?.other_details,
        reherical_time: formValues?.values?.reherical_time,
        services: formValues?.values?.services,
      },
    });
    _.map(_.keys(this.state.allFields), row => {});

    const { bookingStartTime1, bookingEndTime1 } = formValues.values;
    const startDate = bookingStartTime1 ? timestampToDate(bookingStartTime1) : null;
    const endDate = bookingEndTime1 ? timestampToDate(bookingEndTime1) : null;
    this.durationFunction({ startDate1: startDate, endDate1: endDate });
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime1 === bookingEndTime1;
    this.setState({ rehericalData: { startDate, endDate } });
    if (
      bookingStartTime1 &&
      bookingEndTime1 &&
      !isSameTime &&
      !this.props.fetchLineItemsInProgress
    ) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate },
        listingId,
        isOwnListing,
        form: 'second',
      });
    }
  }

  render() {
    let submit;
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    // console.log('price', this.props.price.amount);

    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <>
        <FinalForm
          {...rest}
          unitPrice={unitPrice}
          onSubmit={this.handleFormSubmit}
          render={fieldRenderProps => {
            const {
              endDatePlaceholder,
              startDatePlaceholder,
              form,
              pristine,
              handleSubmit,
              intl,
              isOwnListing,
              listingId,
              submitButtonWrapperClassName,
              unitType,
              values,
              monthlyTimeSlots,
              onFetchTimeSlots,
              timeZone,
              lineItems,
              fetchLineItemsInProgress,
              fetchLineItemsError,
            } = fieldRenderProps;

            submit = handleSubmit;
            const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
            const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

            const bookingStartLabel = intl.formatMessage({
              id: 'BookingTimeForm.bookingStartTitle',
            });
            const bookingEndLabel = intl.formatMessage({
              id: 'BookingTimeForm.bookingEndTitle',
            });

            const startDate = startTime ? timestampToDate(startTime) : null;
            const endDate = endTime ? timestampToDate(endTime) : null;

            // This is the place to collect breakdown estimation data. See the
            // EstimatedBreakdownMaybe component to change the calculations
            // for customized payment processes.
            const bookingData =
              startDate && endDate
                ? {
                    unitType,
                    startDate,
                    endDate,
                    timeZone,
                  }
                : null;

            const showEstimatedBreakdown =
              bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

            const bookingInfoMaybe = showEstimatedBreakdown ? (
              <div className={css.priceBreakdownContainer}>
                <h3 className={css.priceBreakdownTitle}>
                  <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                </h3>
                <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
              </div>
            ) : null;

            const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
              <IconSpinner className={css.spinner} />
            ) : null;

            const bookingInfoErrorMaybe = fetchLineItemsError ? (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
              </span>
            ) : null;

            const submitButtonClasses = classNames(
              submitButtonWrapperClassName || css.submitButtonWrapper
            );

            const startDateInputProps = {
              label: bookingStartLabel,
              placeholderText: startDatePlaceholder,
            };
            const endDateInputProps = {
              label: bookingEndLabel,
              placeholderText: endDatePlaceholder,
            };

            const dateInputProps = {
              startDateInputProps,
              endDateInputProps,
            };
            // this.props.listingData?.attributes?.publicData?.servicePrice?.map(data => {
            //   console.log(data);
            // });
            console.log(' this.props', this.props);
            const [service_price, setServicePrice] = useState(0);
            const handleChange = event => {
              const priceData = getPrice(event.target.value, config.currencyConfig);
              setServicePrice(event.target.value);
              if (+event.target.value > 0) {
                this.props.setupdatedPrice(event.target.value);
                this.props.onUpdateListing(listingId, { price: priceData });
              }
            };

            const { Money } = sdkTypes;

            const getPrice = (unformattedValue, currencyConfig) => {
              const isEmptyString = unformattedValue === '';
              try {
                return isEmptyString
                  ? null
                  : new Money(
                      convertUnitToSubUnit(unformattedValue, unitDivisor(currencyConfig.currency)),
                      currencyConfig.currency
                    );
              } catch (e) {
                return null;
              }
            };

            return (
              <>
                <Form
                  onSubmit={handleSubmit}
                  id="exampleForm"
                  className={classes}
                  enforcePagePreloadFor="CheckoutPage"
                >
                  {/* <h4>Service Fee : $25</h4> */}
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={values => {
                      this.handleOnChange(values, timeZone, unitType);
                    }}
                  />
                  {this?.props?.listingData?.attributes?.publicData?.servicePrice && (
                    <>
                      <InputLabel id="demo-simple-select-label">Service</InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={service_price}
                        onChange={e => {
                          handleChange(e);
                          form.reset();
                        }}
                      >
                        <MenuItem value={0}>Select Service</MenuItem>
                        {this?.props?.listingData?.attributes?.publicData?.servicePrice &&
                          Object.keys(
                            this?.props?.listingData?.attributes?.publicData?.servicePrice
                          ).map((keyName, i) => {
                            return (
                              <MenuItem
                                value={
                                  this?.props?.listingData?.attributes?.publicData?.servicePrice[
                                    keyName
                                  ]
                                }
                              >
                                {keyName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </>
                  )}

                  {monthlyTimeSlots && timeZone ? (
                    <FieldDateAndTimeInput
                      {...dateInputProps}
                      className={css.bookingDates}
                      listingId={listingId}
                      bookingStartLabel={bookingStartLabel}
                      onFetchTimeSlots={onFetchTimeSlots}
                      monthlyTimeSlots={monthlyTimeSlots}
                      values={values}
                      intl={intl}
                      form={form}
                      pristine={pristine}
                      timeZone={timeZone}
                    />
                  ) : null}

                  {/* {bookingInfoMaybe} */}
                  {/* {loadingSpinnerMaybe} */}
                  {bookingInfoErrorMaybe}
                  {/* <div className="descriptions" style={{ margin: '0 0px' }}>
                    <FieldTextInput
                      id="description_church"
                      name="description_church"
                      className={css.bookingDates}
                      type="text"
                      label="What is the name of your church?"
                    // placeholder={descriptionPlaceholderMessage}
                    />

                    <FieldTextInput
                      id="description"
                      name="description"
                      className={css.bookingDates}
                      type="text"
                      label="What is your church&#39;s address?"
                    // placeholder={descriptionPlaceholderMessage}
                    />
                  </div> */}

                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? 'BookingTimeForm.ownListing'
                          : 'BookingTimeForm.youWontBeChargedInfo'
                      }
                    />
                  </p>
                </Form>
              </>
            );
          }}
        />

        {/* second form  */}
        <FinalForm
          {...rest}
          unitPrice={unitPrice}
          onSubmit={this.handleFormSubmit}
          render={fieldRenderProps => {
            const {
              endDatePlaceholder,
              startDatePlaceholder,
              form,
              pristine,
              handleSubmit,
              intl,
              isOwnListing,
              listingId,
              submitButtonWrapperClassName,
              unitType,
              values,
              monthlyTimeSlots,
              onFetchTimeSlots,
              timeZone,
              lineItems,
              secondLineItems,
              fetchLineItemsInProgress,
              fetchLineItemsError,
            } = fieldRenderProps;

            const startTime = values && values.bookingStartTime1 ? values.bookingStartTime1 : null;
            const endTime = values && values.bookingEndTime1 ? values.bookingEndTime1 : null;

            const bookingStartLabel = intl.formatMessage({
              id: 'BookingTimeForm.bookingStartTitle',
            });
            const bookingEndLabel = intl.formatMessage({
              id: 'BookingTimeForm.bookingEndTitle',
            });

            const startDate = startTime ? timestampToDate(startTime) : null;
            const endDate = endTime ? timestampToDate(endTime) : null;

            // This is the place to collect breakdown estimation data. See the
            // EstimatedBreakdownMaybe component to change the calculations
            // for customized payment processes.

            let bookingData1 =
              startDate && endDate && this.state.rehericaltime
                ? {
                    unitType,
                    startDate1: startDate,
                    endDate1: endDate,
                    timeZone,
                  }
                : null;

            const bookingData = this.state.firstPayment ? this.state.firstPayment : {};
            const showEstimatedBreakdown =
              (bookingData || bookingData1) &&
              lineItems &&
              !fetchLineItemsInProgress &&
              !fetchLineItemsError;

            const bookingInfoMaybe = showEstimatedBreakdown ? (
              <div className={css.priceBreakdownContainer}>
                <h3 className={css.priceBreakdownTitle}>
                  <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                </h3>
                <EstimatedBreakdownMaybe
                  bookingData={{ ...bookingData, bookingData1 }}
                  lineItems={lineItems}
                />
              </div>
            ) : null;

            const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
              <IconSpinner className={css.spinner} />
            ) : null;

            const bookingInfoErrorMaybe = fetchLineItemsError ? (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
              </span>
            ) : null;

            const submitButtonClasses = classNames(
              submitButtonWrapperClassName || css.submitButtonWrapper
            );

            const startDateInputProps = {
              label: bookingStartLabel,
              placeholderText: startDatePlaceholder,
            };
            const endDateInputProps = {
              label: bookingEndLabel,
              placeholderText: endDatePlaceholder,
            };

            const dateInputProps = {
              startDateInputProps,
              endDateInputProps,
            };

            const [open, setOpen] = useState(false);

            const handleOpen = () => {
              setOpen(true);
            };
            const handleClose = () => {
              setOpen(false);
            };
            return (
              <>
                <Form
                  onSubmit={handleSubmit}
                  className={classes}
                  enforcePagePreloadFor="CheckoutPage"
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={values => {
                      this.handleOnChange2(values);
                    }}
                  />
                  <FieldCheckbox
                    id="checkbox-id1"
                    name="checkbox-group"
                    label="Add additional rehearsal time"
                    value="option1"
                    onChange={e => {
                      this.showFormData();
                    }}
                  />
                  {this.state.rehericaltime === true && (
                    <FieldDateAndTimeInputReherisal
                      {...dateInputProps}
                      className={css.bookingDates}
                      listingId={listingId}
                      bookingStartLabel="Add reherical time"
                      onFetchTimeSlots={onFetchTimeSlots}
                      monthlyTimeSlots={monthlyTimeSlots}
                      values={values}
                      intl={intl}
                      form={form}
                      pristine={pristine}
                      timeZone={timeZone}
                    />
                  )}

                  {bookingInfoMaybe}
                  {loadingSpinnerMaybe}
                  {bookingInfoErrorMaybe}

                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? 'BookingTimeForm.ownListing'
                          : 'BookingTimeForm.youWontBeChargedInfo'
                      }
                    />
                  </p>
                  <div className={submitButtonClasses}>
                    {/* <PrimaryButton type="button" onClick={() => submit()}>
                      <FormattedMessage id="BookingTimeForm.requestToBook" />
                    </PrimaryButton> */}

                    <PrimaryButton
                      type="button"
                      onClick={handleOpen}
                      disabled={this.state.disabledButton}
                    >
                      <FormattedMessage id="BookingTimeForm.requestToBook" />
                    </PrimaryButton>
                    <br />

                    <PrimaryButton onClick={this.props.onContactUser} type="button">
                      <span>Contact {this.props.contactuser}</span>
                    </PrimaryButton>
                    <BookingPopup
                      open={open}
                      state={this.state.allFields}
                      handleClose={handleClose}
                      onSumitFunc={submit}
                    />
                  </div>
                </Form>
              </>
            );
          }}
        />
      </>
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  listingData: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  secondLineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  onUpdateListing: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  secondLineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
