import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { FieldTextInput } from '../../components';
import css from './BookingTimeForm.module.css';
import './custom.css';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const BookingPopup = ({ open, handleClose, state, onSumitFunc }) => {
  const [step, setStep] = useState(0);
  return (
    <>
      <Dialog open={open} className="CandleDialoge">
        <DialogTitle disableTypography>
          {handleClose ? (
            <IconButton aria-label="close" className="icon" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        <DialogContent className="dialog_content">
          {/* <Box className="Heading1" fontSize="20px" fontWeight="800">
            Are you sure you want to Book this request?
          </Box> */}

          <div className="descriptions">
            {step === 0 && (
              <FieldTextInput
                id="description_church"
                name="description_church"
                className="description_church"
                type="text"
                // onChange={(e) => setFieldsData(e)}
                label="What is the name of your church?"
              // placeholder={descriptionPlaceholderMessage}
              />
            )}
            {step === 1 && (
              <FieldTextInput
                id="description"
                name="description"
                className="description_address"
                type="text"
                // onChange={(e) => setFieldsData(e)}
                label="What is your church&#39;s address?"
              // placeholder={descriptionPlaceholderMessage}
              />
            )}

            {step === 2 && (
              <FieldTextInput
                id="reherical_time"
                name="reherical_time"
                className="reherical_time"
                type="text"
                // onChange={(e) => setFieldsData(e)}
                label="What is your rehearsal time?"
              // placeholder={descriptionPlaceholderMessage}
              />
            )}

            {step === 3 && (
              <FieldTextInput
                id="services"
                name="services"
                className="services"
                type="text"
                // onChange={(e) => setFieldsData(e)}
                label="How many services?"
              // placeholder={descriptionPlaceholderMessage}
              />
            )}

            {step === 4 && (
              <FieldTextInput
                id="other_details"
                name="other_details"
                className="other_details"
                type="textarea"
                // onChange={(e) => setFieldsData(e)}
                label="Any other details you want to include?"
              // placeholder={descriptionPlaceholderMessage}
              />
            )}

            {step === 5 && (
              // <Box className="Heading">Are you sure you want to Book this request?</Box>
              <Box>
                <Button
                  onClick={() => {
                    if (step === 5) {
                      onSumitFunc();
                      handleClose();
                      setStep(0);
                    } else {
                      setStep(step + 1);
                    }
                  }}
                  variant="contained"
                  size="large"
                  className="cancel_button"
                >
                  {step === 5 ? 'Book' : 'Next'}
                </Button>
              </Box>
            )}
          </div>
        </DialogContent>


        <DialogActions
          style={{ justifyContent: 'center', marginBottom: '10px' }}
          className="Dialog_Actions"
        >
          <Box>
            <Button
              onClick={() => {
                if (step === 4) {
                  onSumitFunc();
                  handleClose();
                  setStep(0);
                } else {
                  setStep(step + 1);
                }
              }}
              variant="contained"
              size="large"
              className="cancel_button"
            // disabled={
            //   (step === 0 && state.description_church === undefined) ||
            //   (step === 1 && state.description === undefined) ||
            //   (step === 2 && state.reherical_time === undefined) ||
            //   (step === 3 && state.services === undefined) ||
            //   (step === 4 && state.other_details === undefined)
            // }
            >
              {step === 4 ? 'Book' : 'Next'}
            </Button>
          </Box>
        </DialogActions>

      </Dialog>
    </>
  );
};
export default BookingPopup;
