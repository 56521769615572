import React from 'react';
import { Box, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
    cancelSale,
} from '../../containers/TransactionPage/TransactionPage.duck';
import './cancel.css'


const Cancelmodal = ({ open, handleClose, onCancelSale, transactionId }) => {

    const cancelBooking = () => {
        handleClose();
        onCancelSale(transactionId).then((res) => {
            if (res?.status === 200) {
                location.reload();
            }
        });
    }

    return (
        <>
            <Dialog open={open} className="CandleDialoge">
                <DialogContent className="dialog_content">

                    <Box className="Heading1" fontSize='20px' fontWeight='800'>Are you sure you want to cancel this request?</Box>
                    <Box className="first_para" marginTop='20px'>
                        <Box textAlign='center'>We charge a Cancelletion fee to compensate the provider for</Box>
                        <Box textAlign='center'>missing out on other service oppurtunities and thier time</Box>
                        <Box textAlign='center'>rehearsing and preparing for your service</Box>
                    </Box>
                    <Box marginTop='20px' textAlign='center' className="refund_text">How much will i get refunded?</Box>
                    <Box marginTop='20px' className="last_para" >
                        <Box> 2 week from service date - 100% * less VH fee</Box>
                        <Box> 1 week from service date - 50% * less VH fee</Box>
                        <Box> 5 days from service date - 25% * less VH fee</Box>
                        <Box> 2 days or less from service date -No refund * less VH fee</Box>
                    </Box>

                </DialogContent>
                <DialogActions style={{ justifyContent: "center", marginBottom: '10px' }} className="Dialog_Actions">

                    <Box>
                        <Button
                            style={{
                                backgroundColor: '#8c52ff',

                            }}
                            onClick={handleClose}
                            color="primary"
                            variant="contained"
                            size="large"
                            className="nevermind_button"

                        >
                            Nevermind
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={() => {
                            cancelBooking()

                        }} variant="contained" size="large" className="cancel_button" >
                            Cancel Service
                        </Button>
                    </Box>
                </DialogActions>

            </Dialog>

        </>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        onCancelSale: transactionId => dispatch(cancelSale(transactionId))
    }
}
export default compose(
    withRouter,
    connect(
        null,
        mapDispatchToProps
    ),
    injectIntl
)(Cancelmodal)