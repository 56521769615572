import React, { useEffect, useState } from 'react';
import { bool, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Form,
  InlineTextButton,
  IconClose,
  PrimaryButton,
  FieldSelect,
  FieldTimeZoneSelect,
} from '../../components';

import css from './EditListingAvailabilityPlanForm.module.css';
import { withStyles } from '@material-ui/core';
import './CustomEditListingPlan.css';
import { isMobile } from 'react-device-detect';
import FieldCheckboxComponent from '../../components/FieldCheckbox/FieldCheckbox';
import TimeDialog from './TimeDialog';

const printHourStrings_hour = h => {
  h = h.split(':');
  h = +h[0];
  var ampm = h >= 12 ? 'PM' : 'AM';

  return h > 9 ? `${h > 12 ? h - 12 : h}:00 ${ampm}` : `0${h > 12 ? h - 12 : h}:00 ${ampm}`;
};

const printHourStrings = h => {
  // var ampm = (h >= 12) ? "PM" : "AM";

  return h > 9 ? `${h}:00` : `0${h}:00`;
};
const HOURS = Array(24).fill();
const ALL_START_HOURS = [...HOURS].map((v, i) => printHourStrings(i));
const ALL_END_HOURS = [...HOURS].map((v, i) => printHourStrings(i + 1));

const sortEntries = (defaultCompareReturn = 0) => (a, b) => {
  if (a.startTime && b.startTime) {
    const aStart = Number.parseInt(a.startTime.split(':')[0]);
    const bStart = Number.parseInt(b.startTime.split(':')[0]);
    return aStart - bStart;
  }
  return defaultCompareReturn;
};

const findEntryFn = entry => e => e.startTime === entry.startTime && e.endTime === entry.endTime;

const filterStartHours = (availableStartHours, values, dayOfWeek, index) => {
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no end time selected, return all the available start times
  if (!currentEntry.endTime) {
    return availableStartHours;
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the previous entry
  const sortedEntries = [...entries].sort(sortEntries());

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry or the previous entry does not have endTime,
  // return all the available times before current selected end time.
  // Otherwise return all the available start times that are after the previous entry or entries.
  const prevEntry = sortedEntries[currentIndex - 1];
  const pickBefore = time => h => h < time;
  const pickBetween = (start, end) => h => h >= start && h < end;

  return !prevEntry || !prevEntry.endTime
    ? availableStartHours.filter(pickBefore(currentEntry.endTime))
    : availableStartHours.filter(pickBetween(prevEntry.endTime, currentEntry.endTime));
};

const filterEndHours = (availableEndHours, values, dayOfWeek, index) => {
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no start time selected, return an empty array;
  if (!currentEntry.startTime) {
    return [];
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the allowed start times
  const sortedEntries = [...entries].sort(sortEntries(-1));

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry,
  // return all the available end times that are after the start of current entry.
  // Otherwise return all the available end hours between current start time and next entry.
  const nextEntry = sortedEntries[currentIndex + 1];
  const pickAfter = time => h => h > time;
  const pickBetween = (start, end) => h => h > start && h <= end;

  return !nextEntry || !nextEntry.startTime
    ? availableEndHours.filter(pickAfter(currentEntry.startTime))
    : availableEndHours.filter(pickBetween(currentEntry.startTime, nextEntry.startTime));
};

const getEntryBoundaries = (values, dayOfWeek, intl, findStartHours) => index => {
  const entries = values[dayOfWeek];
  const boundaryDiff = findStartHours ? 0 : 1;

  return entries.reduce((allHours, entry, i) => {
    const { startTime, endTime } = entry || {};

    if (i !== index && startTime && endTime) {
      const startHour = Number.parseInt(startTime.split(':')[0]);
      const endHour = Number.parseInt(endTime.split(':')[0]);
      const hoursBetween = Array(endHour - startHour)
        .fill()
        .map((v, i) => printHourStrings(startHour + i + boundaryDiff));

      return allHours.concat(hoursBetween);
    }

    return allHours;
  }, []);
};

const SwitchButton = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#0169fe',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#0169fe',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const DailyPlan = props => {
  const {
    dayOfWeek,
    values,
    intl,
    state,
    setState,
    daywiseTime,
    setDayWiseTime,
    openTimeDialog,
    setOpenTimeDialog,
    setCurrentDay,
    currentDay,
    setCurrentIndex,
    currentindex,
    currentPeriods,
    setCurrentPeriods,
  } = props;

  const getEntryStartTimes = getEntryBoundaries(values, dayOfWeek, intl, true);
  const getEntryEndTimes = getEntryBoundaries(values, dayOfWeek, intl, false);

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleChangeCheck = e => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };

  const handleChangeTime = (value, period, day, index) => {
    // console.log(
    //   'index',
    //   index,
    //   'currentPeriods',
    //   currentPeriods,
    //   'value',
    //   value,
    //   'period',
    //   period,
    //   'day',
    //   day,
    //   'currentDay',
    //   currentDay
    // );
    setCurrentPeriods({ ...currentPeriods, [period]: value });
    // daywiseTime[currentDay][index][period] = value;

    // setDayWiseTime(daywiseTime);
  };

  const onCancelTimeSelection = () => {
    daywiseTime[currentDay][currentindex] = currentPeriods;

    setDayWiseTime(daywiseTime);
  };
  // console.log('daywiseTime', daywiseTime)
  const hasEntries = values[dayOfWeek] && values[dayOfWeek][0];

  const startTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.startTimePlaceholder',
  });
  const endTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.endTimePlaceholder',
  });
  // console.log("dayOfWeek", dayOfWeek);
  return (
    <div
      className={classNames(
        `${css.weekDay} WeekDaysMain  ${!isMobile ? 'desktop_custom_avalibility_date' : ''}`,
        hasEntries ? `${css.hasEntries} HasentriesMAin` : null
      )}
    >
      <div className={isMobile ? 'mobileWeekdays' : `${css.dayOfWeek} weekdays `}>
        {isMobile ? (
          <FormControlLabel
            control={
              <SwitchButton checked={state[dayOfWeek]} onChange={handleChange} name={dayOfWeek} />
            }
          />
        ) : (
          <input
            type="checkbox"
            checked={state[dayOfWeek]}
            name={dayOfWeek}
            className="Checkbox_for_desktop"
            onChange={e => {
              handleChangeCheck(e);
            }}
          />
        )}

        <div className={isMobile ? 'DaysofweekName' : ''}>{dayOfWeek.toUpperCase()}</div>
      </div>
      <FieldArray name={dayOfWeek}>
        {({ fields }) => {
          return (
            <div className={`${css.timePicker} TimepickerCustom`}>
              {fields.map((name, index) => {
                // Pick available start hours
                const pickUnreservedStartHours = h => !getEntryStartTimes(index).includes(h);
                const availableStartHours = ALL_START_HOURS.filter(pickUnreservedStartHours);

                // Pick available end hours
                const pickUnreservedEndHours = h => !getEntryEndTimes(index).includes(h);
                const availableEndHours = ALL_END_HOURS.filter(pickUnreservedEndHours);
                let sArrayStart = [];
                let sArrayEnd = [];
                // useEffect(() => {
                //   {
                //     filterEndHours(availableEndHours, values, dayOfWeek, index).map(s => {
                //       // var datas = s.split(' ')
                //       sArrayEnd.push(s);
                //       // return (
                //       //   <option value={s} key={s}>
                //       //     {printHourStrings_hour(s)}
                //       //   </option>
                //       // <option value={datas[0]} key={1 + (Math.random() * (100 - 1))}>
                //       //   {s}
                //       // </option>
                //       // );
                //     })
                //   }
                //   {
                //     filterEndHours(availableEndHours, values, dayOfWeek, index).map(s => {
                //       // var datas = s.split(' ')
                //       sArrayEnd.push(s);
                //       // return (
                //       //   <option value={s} key={s}>
                //       //     {printHourStrings_hour(s)}
                //       //   </option>
                //       // <option value={datas[0]} key={1 + (Math.random() * (100 - 1))}>
                //       //   {s}
                //       // </option>
                //       // );
                //     })
                //   }
                // }, [])
                // console.log(
                //   'sssss',
                //   daywiseTime[dayOfWeek][index]?.['startTime'],
                //   daywiseTime[dayOfWeek][index]?.['endTime']

                // );
                return (
                  <>
                    <div className={`${css.fieldWrapper} FieldWraper`} key={name}>
                      <div className={`${css.formRow} FormRow`}>
                        <div className={`${css.field} FieldCustom`}>
                          <FieldSelect
                            onClick={() => {
                              if (isMobile) {
                                setCurrentIndex(index);
                                setCurrentDay(dayOfWeek);
                                setOpenTimeDialog(true);
                              }
                            }}
                            disabled={!state[dayOfWeek] && true}
                            id={`${name}.startTime`}
                            name={`${name}.startTime`}
                            selectClassName={
                              !state[dayOfWeek]
                                ? `${css.fieldSelect} disabled_field `
                                : `${css.fieldSelect} start_time `
                            }
                          >
                            {isMobile ? (
                              daywiseTime[dayOfWeek][index]?.['startTime'] ? (
                                <option
                                  selected={
                                    daywiseTime[dayOfWeek][index]?.['startTime'] !== '' && true
                                  }
                                  value={
                                    daywiseTime[dayOfWeek][index]?.['startTime'] !== ''
                                      ? daywiseTime[dayOfWeek][index]?.['startTime']
                                      : ''
                                  }
                                  className="selected"
                                >
                                  {printHourStrings_hour(
                                    daywiseTime[dayOfWeek][index]?.['startTime']
                                  )}
                                </option>
                              ) : (
                                <option disabled value="">
                                  {startTimePlaceholder}
                                </option>
                              )
                            ) : (
                              filterStartHours(availableEndHours, values, dayOfWeek, index).map(
                                s => {
                                  // var datas = s.split(' ')
                                  // sArrayEnd.push(s);
                                  return (
                                    <option value={s} key={s}>
                                      {printHourStrings_hour(s)}
                                    </option>
                                    // <option value={datas[0]} key={1 + (Math.random() * (100 - 1))}>
                                    //   {s}
                                    // </option>
                                  );
                                }
                              )
                            )}

                            {filterStartHours(availableStartHours, values, dayOfWeek, index).map(
                              s => {
                                sArrayStart.push(s);
                              }
                            )}
                          </FieldSelect>
                        </div>
                        <span className={`${css.dashBetweenTimes} DashBetweenTimesCustom`}>-</span>
                        <div className={`${css.field} FieldsCustomdiv`}>
                          <FieldSelect
                            onClick={() => {
                              if (isMobile) {
                                setCurrentIndex(index);

                                setCurrentDay(dayOfWeek);
                                setOpenTimeDialog(true);
                              }
                            }}
                            disabled={!state[dayOfWeek] && true}
                            id={`${name}.endTime`}
                            name={`${name}.endTime`}
                            selectClassName={
                              !state[dayOfWeek]
                                ? `${css.fieldSelect} disabled_field`
                                : `${css.fieldSelect} end_time`
                            }
                          >
                            {isMobile ? (
                              daywiseTime[dayOfWeek][index]?.['endTime'] ? (
                                <option
                                  selected={daywiseTime[dayOfWeek]?.['endTime'] !== '' && true}
                                  value={
                                    daywiseTime[dayOfWeek]?.['endTime'] !== ''
                                      ? daywiseTime[dayOfWeek]?.['endTime']
                                      : ''
                                  }
                                >
                                  {printHourStrings_hour(
                                    daywiseTime[dayOfWeek][index]?.['endTime']
                                  )}
                                </option>
                              ) : (
                                <option disabled value="">
                                  {endTimePlaceholder}
                                </option>
                              )
                            ) : (
                              filterEndHours(availableEndHours, values, dayOfWeek, index).map(s => {
                                // var datas = s.split(' ')
                                return (
                                  <option value={s} key={s}>
                                    {printHourStrings_hour(s)}
                                  </option>
                                  // <option value={datas[0]} key={1 + (Math.random() * (100 - 1))}>
                                  //   {s}
                                  // </option>
                                );
                              })
                            )}
                            {filterEndHours(availableEndHours, values, dayOfWeek, index).map(s => {
                              sArrayEnd.push(s);
                            })}
                          </FieldSelect>
                        </div>
                      </div>
                      {/* <div className="addmorebutton">
                        <InlineTextButton
                          disabled={!state[dayOfWeek] && true}
                          type="button"
                          className={
                            !state[dayOfWeek]
                              ? `${css.buttonAddNew} disable_button`
                              : css.buttonAddNew
                          }
                        // onClick={() => fields.push({ startTime: null, endTime: null })}
                        >
                          <FormattedMessage id="EditListingAvailabilityPlanForm.addAnother" />
                        </InlineTextButton>
                      </div> */}
                      <div
                        className={css.fieldArrayRemove}
                        onClick={() => fields.remove(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        <DeleteIcon className="DeleteICon" />
                      </div>
                    </div>
                    {isMobile && (
                      <TimeDialog
                        currentPeriods={currentPeriods}
                        openTimeDialog={openTimeDialog}
                        setOpenTimeDialog={setOpenTimeDialog}
                        printHourStrings_hour={printHourStrings_hour}
                        sArrayStart={sArrayStart}
                        sArrayEnd={sArrayEnd}
                        dayOfWeek={dayOfWeek}
                        setDayWiseTime={(value, period, dayOfWeeks) =>
                          handleChangeTime(value, period, dayOfWeeks, index)
                        }
                        onCancelTimeSelection={onCancelTimeSelection}
                      />
                    )}
                  </>
                );
              })}

              {isMobile
                ? fields.length === 0 && (
                    <InlineTextButton
                      type="button"
                      className={`${css.buttonSetHours} buttonSethours`}
                      onClick={() => fields.push({ startTime: null, endTime: null })}
                    >
                      <FormattedMessage id="EditListingAvailabilityPlanForm.setHours" />
                    </InlineTextButton>
                  )
                : fields.length === 0 && (
                    <InlineTextButton
                      type="button"
                      className={`${css.buttonSetHours} buttonSethours`}
                      onClick={() => fields.push({ startTime: null, endTime: null })}
                    >
                      <FormattedMessage id="EditListingAvailabilityPlanForm.setHours" />
                    </InlineTextButton>
                  )}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

const submit = (onSubmit, weekdays) => values => {
  const sortedValues = weekdays.reduce(
    (submitValues, day) => {
      return submitValues[day]
        ? {
            ...submitValues,
            [day]: submitValues[day].sort(sortEntries()),
          }
        : submitValues;
    },
    { ...values }
  );

  onSubmit(sortedValues);
};

const EditListingAvailabilityPlanFormComponent = props => {
  const { onSubmit, ...restOfprops } = props;
  const [state, setState] = React.useState({
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  });
  const [daywiseTime, setDayWiseTime] = useState({
    mon: props.initialValues?.mon ? props.initialValues.mon : [{ startTime: '', endTime: '' }],
    tue: props.initialValues?.tue ? props.initialValues.tue : [{ startTime: '', endTime: '' }],
    wed: props.initialValues?.wed ? props.initialValues.wed : [{ startTime: '', endTime: '' }],
    thu: props.initialValues?.thu ? props.initialValues.thu : [{ startTime: '', endTime: '' }],
    fri: props.initialValues?.fri ? props.initialValues.fri : [{ startTime: '', endTime: '' }],
    sat: props.initialValues?.sat ? props.initialValues.sat : [{ startTime: '', endTime: '' }],
    sun: props.initialValues?.sun ? props.initialValues.sun : [{ startTime: '', endTime: '' }],
  });
  return (
    <FinalForm
      {...restOfprops}
      onSubmit={e => {
        e['weekdays'] = daywiseTime;

        props.onSubmit(e);
      }}
      // onSubmit={submit(onSubmit, props.weekdays)}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          listingTitle,
          weekdays,
          fetchErrors,
          values,
        } = fieldRenderProps;
        console.log('props.initialValues', props.initialValues);
        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const [openTimeDialog, setOpenTimeDialog] = useState(false);
        const [currentDay, setCurrentDay] = useState('');
        const [currentindex, setCurrentIndex] = useState();
        const [currentPeriods, setCurrentPeriods] = useState();

        const concatDayEntriesReducer = (entries, day) =>
          values[day] ? entries.concat(values[day]) : entries;
        const hasUnfinishedEntries = !!weekdays
          .reduce(concatDayEntriesReducer, [])
          .find(e => !e.startTime || !e.endTime);

        const { updateListingError } = fetchErrors || {};

        const submitDisabled = submitInProgress || hasUnfinishedEntries;

        return (
          <Form id={formId} className={classes} onSubmit={handleSubmit}>
            <h2 className={css.heading}>
              <FormattedMessage
                id="EditListingAvailabilityPlanForm.title"
                values={{ listingTitle }}
              />
            </h2>
            <h3 className={css.subheading}>
              <FormattedMessage id="EditListingAvailabilityPlanForm.timezonePickerTitle" />
            </h3>
            <div className={css.timezonePicker}>
              <FieldTimeZoneSelect id="timezone" name="timezone" />
            </div>
            <h3 className={css.subheading}>
              <FormattedMessage id="EditListingAvailabilityPlanForm.hoursOfOperationTitle" />
            </h3>
            <div
              className={`${css.week} WeeekMAin ${
                !isMobile ? 'desktop_custom_avalibility_date_main' : ''
              }`}
            >
              {weekdays.map(w => {
                return (
                  <DailyPlan
                    currentPeriods={currentPeriods}
                    setCurrentPeriods={setCurrentPeriods}
                    setCurrentIndex={setCurrentIndex}
                    currentindex={currentindex}
                    currentDay={currentDay}
                    setCurrentDay={setCurrentDay}
                    openTimeDialog={openTimeDialog}
                    setOpenTimeDialog={setOpenTimeDialog}
                    daywiseTime={daywiseTime}
                    setDayWiseTime={setDayWiseTime}
                    dayOfWeek={w}
                    key={w}
                    values={values}
                    intl={intl}
                    state={state}
                    setState={setState}
                  />
                );
              })}
            </div>

            <div className={css.submitButton}>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingAvailabilityPlanForm.updateFailed" />
                </p>
              ) : null}
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={isMobile ? false : submitDisabled}
              >
                <FormattedMessage id="EditListingAvailabilityPlanForm.saveSchedule" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingAvailabilityPlanFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

EditListingAvailabilityPlanFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  fetchErrors: object.isRequired,

  listingTitle: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingAvailabilityPlanForm = compose(injectIntl)(
  EditListingAvailabilityPlanFormComponent
);

EditListingAvailabilityPlanForm.displayName = 'EditListingAvailabilityPlanForm';

export default EditListingAvailabilityPlanForm;
