import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingVideosForm } from '../../forms';
import config from '../../config';

import css from './EditListingVideosPanel.module.css';

const EditListingVideosPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const formClass = classNames(css.form, css.videoForm);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

console.log('publicData..............',publicData)
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingVideosPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingVideosPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingVideosPanel.createListingTitle" />
  );

  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingVideosForm
        className={formClass}
        initialValues={{ video_link1: publicData?.videoUrls?.link1, video_link2: publicData?.videoUrls?.link2, video_link3: publicData?.videoUrls?.link3, video_link4: publicData?.videoUrls?.link4 }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {  video_link1, video_link2, video_link3, video_link4 } = values;
          const updateValues = {
            publicData: { videoUrls: {
              link1: video_link1,
              link2: video_link2,
              link3: video_link3,
              link4: video_link4
            }},
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        certificateOptions={certificateOptions}
      />
    </div>
  );
};

EditListingVideosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingVideosPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingVideosPanel;
