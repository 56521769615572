/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { useState } from 'react';
import { any, oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemUnitPriceMaybe from './LineItemUnitPriceMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import css from './BookingBreakdown.module.css';
import Cancelmodal from './Cancelmodal';
import { Box } from '@material-ui/core';

export const BookingBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transaction,
    booking,
    intl,
    dateType,
    timeZone,
    duration,
    onCancelSale,
    selectedDate = null,
    isTransaction = false
  } = props;
  console.log('Booking--------------=========>',props.booking);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const hasCommissionLineItem = transaction.attributes.lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const classes = classNames(rootClassName || css.root, className);

  /**
   * BookingBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */
  // console.log('transaction?.attributes?.lastTransition', props?.selectedDates);
  // let convertToArrIfStr =
  //   typeof props?.selectedDates === 'string'
  //     ? JSON.parse(props?.selectedDates)
  //     : props?.selectedDates;

  // let selectedDates = [...convertToArrIfStr];
  // selectedDates?.shift();
  let selectedDates = typeof props?.selectedDates === 'string'
      ? (isTransaction ? JSON.parse(props?.selectedDates)?.filter((val) => moment(val.bookingStartDate).format('YYYY-MM-DD') == moment(selectedDate).tz(timeZone).format('YYYY-MM-DD')) : JSON.parse(props?.selectedDates))
      : (isTransaction ? props?.selectedDates?.filter((val) => moment(val.bookingStartDate).format('YYYY-MM-DD') == moment(selectedDate).tz(timeZone).format('YYYY-MM-DD')) : props?.selectedDates);
  console.log('selectedDates', {selectedDates, selectedDate, isTransaction})
  return (
    <div className={classes}>
      {/* <LineItemBookingPeriod
        booking={booking}
        unitType={unitType}
        dateType={dateType}
        timeZone={timeZone}
      /> */}
      {selectedDates?.map((val, ind) => {
        console.log('val', booking)
        return (
          // <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '8px' }}>
          //   <div>
          //     <p style={{ fontSize: '14px', lineHeight: '24px', fontWeight: '500', margin: 0 }}>
          //      1 {moment(val.bookingStartDate).format('MMM DD')}
          //     </p>
          //   </div>
          //   <div style={{ textAlign: 'right' }}>
          //     <p style={{ fontSize: '14px', lineHeight: '24px', fontWeight: '500', margin: 0 }}>
          //      2 {moment(val.bookingStartDate).format('MMM DD')}
          //     </p>
          //   </div>
          // </div>
          <LineItemBookingPeriod
          booking={{...booking,attributes:{start:val.bookingStartDate,end:val.bookingEndDate}}}
          unitType={unitType}
          dateType={dateType}
          timeZone={timeZone}
          index={ind}
        />
        );
      })}

      <hr className={css.totalDivider} />

      <LineItemUnitPriceMaybe transaction={transaction} unitType={unitType} intl={intl} />
      <LineItemBasePriceMaybe
        transaction={transaction}
        unitType={unitType}
        intl={intl}
        duration={duration}
      />
      {/* sadfsf */}
      {props?.bookingData?.rehericaltime && props?.bookingData?.reherical_date.startDate && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <p style={{ color: '#b2b2b2', fontSize: '14px', margin: 0 }}>Rehearsal start</p>
            <p style={{ fontSize: '16px', fontWeight: '600', margin: '0', letterSpacing: '0.2px' }}>
              {moment(props?.bookingData?.reherical_date.startDate).format('ddd h:mm A')}
            </p>
            <p style={{ fontSize: '14px', lineHeight: '24px', fontWeight: '500', margin: 0 }}>
              {moment(props?.bookingData?.reherical_date.startDate).format('MMM DD')}
            </p>
          </div>
          <div style={{ textAlign: 'right' }}>
            <p style={{ color: '#b2b2b2', fontSize: '14px', margin: 0 }}>Rehearsal end</p>
            <p style={{ fontSize: '16px', fontWeight: '600', margin: '0', letterSpacing: '0.2px' }}>
              {moment(props?.bookingData?.reherical_date.endDate).format('ddd h:mm A')}
            </p>
            <p style={{ fontSize: '14px', lineHeight: '24px', fontWeight: '500', margin: 0 }}>
              {moment(props?.bookingData?.reherical_date.endDate).format('MMM DD')}
            </p>
          </div>
        </div>
      )}
      <LineItemUnknownItemsMaybe transaction={transaction} isProvider={isProvider} intl={intl} />
      <LineItemSubTotalMaybe
        transaction={transaction}
        unitType={unitType}
        userRole={userRole}
        intl={intl}
      />
      <LineItemRefundMaybe transaction={transaction} intl={intl} />
      <LineItemCustomerCommissionMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemCustomerCommissionRefundMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemProviderCommissionMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />
      <LineItemProviderCommissionRefundMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />
      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        duration={duration}
        intl={intl}
      />
      <Cancelmodal open={open} handleClose={handleClose} transactionId={transaction?.id} />
      {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="BookingBreakdown.commissionFeeNote" />
        </span>
      ) : null}
      {isCustomer && transaction?.attributes?.lastTransition === 'transition/accept' && (
        <Box marginTop="28px">
          <Button size="large" variant="contained" fullWidth onClick={handleOpen}>
            {' '}
            Cancel Request
          </Button>
        </Box>
      )}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateType: null,
  timeZone: null,
};

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,
  duration: any,
  onCancelSale: any,
  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
