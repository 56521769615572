import React, { Component, useState } from 'react';
import PropTypes, { array, object } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, LocationAutocompleteInput } from '../../components';
import css from './TopbarSearchForm.module.css';
import TopbarSearchFormHome from './TopbarSearchFormHome';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import TopbarSearchFormAdvance from './TopbarSearchFormAdvance';
import { Link } from 'react-router-dom';

const identity = v => v;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
    this.state = {
      selectedList: [],
      isServiceFilterExisting: false,
      selectedServiceList: [],
      selectedList: [],
      isCityFilterExisting: false,
    };
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, intl, isMobile } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();

          const setSelected = data => {
            this.setState({
              selectedList: data,
            });
          };
          const setSelectedService = data => {
            this.setState({
              selectedServiceList: data,
            });
          };
          let selected_city = this.props?.urlQueryParams?.city?.split(',');
          if (this.state.isCityFilterExisting === false && selected_city?.length > 0) {
            let defaultseletedCity = [];
            this.setState({
              isCityFilterExisting: true,
            });

            selected_city.map(city => {
              if (city != '') {
                defaultseletedCity.push({ name: city, id: city });
              }
            });
            this.setState({
              selectedList: defaultseletedCity,
            });
          }

          const serviceRequestArray = {
            worship_leader: 'Worship Leader',
            drummer: 'Drummer',
            bassist: 'Bassist',
            pianist: 'Keys',
            acoustic_guitar: 'Acoustic Guitar',
            electric_guitar: 'Electric Guitar',
            vocalist: 'Vocalist',
            tech: 'Tech',
            special_events: 'Special events',
            organist: 'Organist',
          };

          let selected_service_list = this.props?.urlQueryParams?.pub_yogaStyles?.split(',');
          if (this.state.isServiceFilterExisting === false && selected_service_list?.length > 0) {
            let defaultseletedService = [];
            this.setState({
              isServiceFilterExisting: true,
            });

            selected_service_list.map(service => {
              if (service != '') {
                defaultseletedService.push({ name: service, id: serviceRequestArray[service] });
              }
            });
            this.setState({
              selectedServiceList: defaultseletedService,
            });
          }

          const handleSubmit = values => {
            const { currentSearchParams } = this.props;

            // const keywords =
            //   this.state.selectedServiceList.map(({ id, name }) => `${name}`).join(',') !== ''
            //     ? this.state.selectedServiceList.map(({ name, id }) => `${name}`).join(',')
            //     : null;
            const daterange = values?.daterange;
            const cityvalues =
              this.state.selectedList.map(({ name, id }) => `${id}`).join(',') !== ''
                ? this.state.selectedList.map(({ name, id }) => `${id}`).join(',')
                : null;
            const { history } = this.props;
            if (values.isClear === true) {
              const searchParams = {
                pub_yogaStyles: null,
                dates: null,
                city: null,
                price: null,
              };
              history.push(
                createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
              );
              // window.location.reload();
              return;
            }
            if (values?.location !== '') {
              const { search, selectedPlace } = values?.location;

              const { origin, bounds } = selectedPlace;

              const originMaybe = config.sortSearchByDistance ? { origin } : {};
              const searchParams = {
                ...currentSearchParams,
                ...originMaybe,

                address: search,
                bounds,

                pub_yogaStyles: values?.keywords,
                dates: daterange,
                city: cityvalues,
              };
              history.push(
                createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
              );
            } else {
              const searchParams = {
                pub_yogaStyles: values?.keywords,
                dates: daterange,
                city: cityvalues,
              };
              history.push(
                createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
              );
            }
          };

          return (
            <Form
              className={classes}
              onSubmit={preventFormSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    this.onChange(value);
                  };

                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (
                    // <LocationAutocompleteInput
                    //   className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                    //   iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                    //   inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                    //   predictionsClassName={
                    //     isMobile ? css.mobilePredictions : css.desktopPredictions
                    //   }
                    //   predictionsAttributionClassName={
                    //     isMobile ? css.mobilePredictionsAttribution : null
                    //   }
                    //   placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                    //   closeOnBlur={!isMobile}
                    //   inputRef={node => {
                    //     this.searchInput = node;
                    //   }}
                    //   input={searchInput}
                    //   meta={meta}
                    // />
                    isMobile ? (
                      <TopbarSearchFormAdvance
                        className={css.searchLink}
                        desktopInputRoot={css.topbarSearchWithLeftPadding}
                        onSubmit={handleSubmit}
                        setSelected={setSelected}
                        selectedList={this.state.selectedList}
                        selectedServiceList={this.state.selectedServiceList}
                        setSelectedService={setSelectedService}
                        urlQueryParams={this.props.urlQueryParams}
                        // initialValues={initialSearchFormValues}
                      />
                    ) : (
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Link
                        className={`${css.createListingLink} contactus`}
                        to={{ pathname: 'https://forms.gle/RouhMv7HqqpY2GUa7' }}
                        target="_blank"
                      >
                        <span className={css.createListing}>Contact Us</span>
                      </Link>
                                            <Link
                        className={`${css.createListingLink} contactus`}
                        to={{ pathname: 'https://forms.gle/eR2gQjuroDTwGhn58' }}
                        target="_blank"
                      >
                        <span className={css.createListing}>Launch in your City</span>
                      </Link>
                      </div>


                    )
                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  urlQueryParams: object,
  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
