import React, { Component, useState } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import './Provider.css';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';
import CallIcon from '@material-ui/icons/Call';
import css from './TransactionPanel.module.css';
import { SecondaryButton } from '../Button/Button';
import { Box } from '@material-ui/core';
import './quesans.css';
import './teanactionpanel_custom.css';
import { isMobile } from 'react-device-detect';
import CloseIcon from '@material-ui/icons/Close';
import { addUserEventCustomerIO } from '../../util/api';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      isCallToProvider: false,
      show_booking_details: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  sendMessageTo = messageData => {
    fetch(`/api/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(messageData),
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
        } else {
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage, currentTransaction, currentUser } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    let customer_name = currentUser?.attributes?.profile?.displayName;

    let provider_number =
      currentUser?.id?.uuid === ensuredTransaction.customer.id.uuid
        ? ensuredTransaction.provider?.attributes?.profile?.publicData?.phoneNumber
        : ensuredTransaction.customer?.attributes?.profile?.publicData?.phoneNumber;
    const messageDataProvider = {
      to: provider_number,
      body: `${customer_name} sent you a message.View and respond here: : https://www.worshipserve.com/order/${ensuredTransaction?.id?.uuid}/details`,
    };
    const messageDataProvider_only = {
      to: provider_number,
      body: `You have new message in your inbox from (${customer_name}) https://www.worshipserve.com/sale/${ensuredTransaction?.id?.uuid}/details`,
    };

    this.sendMessageTo(messageDataProvider);

    if (currentUser?.id?.uuid === ensuredTransaction.customer.id.uuid) {
      this.sendMessageTo(messageDataProvider_only);
    }
    // const currentProvider = ensureUser(ensuredTransaction);
    console.log(
      ensuredTransaction.provider.id.uuid,
      ensuredTransaction.customer.id.uuid,
      currentUser?.id?.uuid
    );
    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      onCancelSale,

      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      secondLineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onUpdateListing,
    } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    // console.log('currentUser,currentCustomer', currentUser?.id?.uuid, currentCustomer?.id?.uuid)
    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => {
          console.log('currentProvidercurrentProvider', {
            price: price.amount / 100,
            currentTransaction: currentTransaction?.booking?.attributes?.displayStart,
          });

          const data = {
            name: 'booking_accepted',
            provider_id: currentProvider?.id?.uuid,
            customer_id: currentCustomer?.id?.uuid,
            booking_date: currentTransaction?.booking?.attributes?.displayStart,
            provider_hourly_rate: price.amount / 100,
          };
          addUserEventCustomerIO(data);

          console.log('booking_accepted', data);
          const data2 = {
            name: 'booking_paid',
            provider_id: currentProvider?.id?.uuid,
            customer_id: currentCustomer?.id?.uuid,
            booking_date: currentTransaction?.booking?.attributes?.displayStart,
          };
          addUserEventCustomerIO(data2);
          onAcceptSale(
            currentTransaction.id,
            currentCustomer,
            currentProvider,
            currentListing,
            currentTransaction
          );
        }}
        onDeclineSale={() => {
          const data = {
            name: 'booking_cancelled',
            provider_id: currentProvider?.id?.uuid,
            customer_id: currentCustomer?.id?.uuid,
            booking_date: currentTransaction?.booking?.attributes?.displayStart,
            user_type: 'provider',
          };
          addUserEventCustomerIO(data);
          console.log('booking_cancelled', data);

          const data2 = {
            name: 'booking_declined',
            provider_id: currentProvider?.id?.uuid,
            customer_id: currentCustomer?.id?.uuid,
          };
          addUserEventCustomerIO(data2);
          onDeclineSale(currentTransaction.id, currentCustomer, currentProvider, currentListing);
        }}
        onCancelSale={() => {
          onCancelSale(currentTransaction.id);
        }}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const callbutton = () => {
      return (
        <>
          {currentUser?.id?.uuid === currentCustomer?.id?.uuid && (
            <a href={`tel:${currentProvider?.attributes?.profile?.publicData?.phoneNumber}`}>
              <button
                className={`${css.submitButton} call-button`}
                onClick={() => {
                  this.setState({ isCallToProvider: !this.state.isCallToProvider });
                }}
              >
                {/* <svg height={21} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                      </svg> */}

                {/* <FormattedMessage id={`Call ${currentProvider.attributes.profile.displayName}`} /> */}
                {!isMobile ? <FormattedMessage id={`Call`} /> : <CallIcon />}
              </button>
            </a>
          )}
        </>
      );
    };

    const callUserOrProvider = () => {
      if (currentUser?.id?.uuid != currentCustomer?.id?.uuid) {
        return (
          <a href={`tel:${currentCustomer?.attributes?.profile?.publicData?.phoneNumber}`}>
            <button className="open_details_class">
              Call {currentCustomer?.attributes?.profile?.displayName}
            </button>
          </a>
        );
      } else {
        return (
          <a href={`tel:${currentProvider?.attributes?.profile?.publicData?.phoneNumber}`}>
            <button className="open_details_class">
              Call {currentProvider?.attributes?.profile?.displayName}
            </button>
          </a>
        );
      }
    };
    console.log('stateData', stateData, this.props.transaction);
    return (
      <div className={`${classes} tranaction_inner_2nd_top`}>
        <div className={`${css.container} trranaction_inner_top`}>
          <div className={`${css.txInfo} tranaction_messae_inner`}>
            {!isMobile && (
              <>
                <DetailCardImage
                  rootClassName={css.imageWrapperMobile}
                  avatarWrapperClassName={css.avatarWrapperMobile}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingDeleted={listingDeleted}
                />

                <div className={css.bookingDetailsMobile}>
                  <AddressLinkMaybe
                    rootClassName={css.addressMobile}
                    location={location}
                    geolocation={geolocation}
                    showAddress={stateData.showAddress}
                  />
                  <BreakdownMaybe
                    transaction={currentTransaction}
                    transactionRole={transactionRole}
                    bookingData={{ ...transaction?.attributes?.metadata }}
                    // onCancelSale={() => onCancelSale(currentTransaction.id)}
                  />
                </div>
              </>
            )}

            {isMobile && this.state.show_booking_details ? (
              <div className="booking_details_mobile">
                <CloseIcon
                  className="close_details_class"
                  onClick={() => {
                    this.setState({ show_booking_details: false });
                  }}
                />

                <DetailCardImage
                  rootClassName={css.imageWrapperMobile}
                  avatarWrapperClassName={css.avatarWrapperMobile}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingDeleted={listingDeleted}
                />
                {/* {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null} */}

                {/* <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            /> */}

                <div className={css.bookingDetailsMobile}>
                  <AddressLinkMaybe
                    rootClassName={css.addressMobile}
                    location={location}
                    geolocation={geolocation}
                    showAddress={stateData.showAddress}
                  />
                  <BreakdownMaybe
                    transaction={currentTransaction}
                    transactionRole={transactionRole}
                    bookingData={{ ...transaction?.attributes?.metadata }}
                    // onCancelSale={() => onCancelSale(currentTransaction.id)}
                  />
                </div>
              </div>
            ) : isMobile ? (
              <div className="ShowButton_LIST">
                <button
                  className="open_details_class"
                  onClick={() => {
                    this.setState({ show_booking_details: true });
                  }}
                >
                  Show booking details
                </button>
                {stateData.headingState === 'accepted' && callUserOrProvider()}
              </div>
            ) : (
              ''
            )}

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}

            {/* <Box>
              <div className="ques" style={{ fontWeight: '800' }}>
                What is the name of your church?
              </div>
              <span className="answer" style={{ fontWeight: 'inherit' }}>
                {currentTransaction?.attributes?.metadata?.church}
              </span>

              <div className="ques" style={{ fontWeight: '800' }}>
                What is the address of the church?
              </div>
              <span className="answer" style={{ fontWeight: 'inherit' }}>
                {currentTransaction?.attributes?.metadata?.address}
              </span>

              <div className="ques" style={{ fontWeight: '800' }}>
                When is rehearsal time?
              </div>
              <span className="answer" style={{ fontWeight: 'inherit' }}>
                {currentTransaction?.attributes?.metadata?.reherical_time}
              </span>

              <div className="ques" style={{ fontWeight: '800' }}>
                How many services?
              </div>
              <span className="answer" style={{ fontWeight: 'inherit' }}>
                {currentTransaction?.attributes?.metadata?.services}
              </span>

              <div className="ques" style={{ fontWeight: '800' }}>
                Any other details you would like to include?
              </div>
              <span className="answer" style={{ fontWeight: 'inherit' }}>
                {currentTransaction?.attributes?.metadata?.other_details}
              </span>
            </Box> */}

            <FeedSection
              rootClassName={`${css.feedContainer} feed_main_cus`}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            />

            {showSendMessageForm ? (
              <>
                {/* stateData.showSaleButtons */}
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={`${
                    css.sendMessageForm
                  } send_message_main ${stateData.showSaleButtons && 'action_button_space'}`}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                  Callbutton={callbutton}
                  addBottomSpace={stateData.showBookingPanel}
                />
              </>
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  secondLineItems={secondLineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  onUpdateListing={onUpdateListing}
                />
              ) : null}
              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                bookingData={{ ...transaction?.attributes?.metadata }}
                isTransaction={true}
                // onCancelSale={(e) => onCancelSale(currentTransaction.id)}
              />

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  secondLineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,
  onUpdateListing: func.isRequired,
  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  onCancelSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  secondLineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
