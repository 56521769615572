import React, { Component, useEffect, useState, Fragment } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { Form, Avatar, Button, ImageFromFile, IconSpinner, FieldTextInput } from '../../components';
import css from './ProfileSettingsForm.module.css';
import StripePayoutPage from '../../../src/containers/StripePayoutPage/StripePayoutPage';
import PaymentMethodsPage from '../../containers/PaymentMethodsPage/PaymentMethodsPage';
import ReactSelect from 'react-select';
import DeleteIcon from '../../assets/DeleteIcon.svg';

import {
  Box,
  FormControl,
  Grid,
  Select,
  Container,
  MenuItem,
  Paper,
  createTheme,
  ThemeProvider,
  Switch,
} from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';

// import { Switch } from 'antd';
// import 'antd/dist/antd.css';
import MultiSelect from './MultiSelect';
const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

function Submit(value) {
  handleFunction = value;
}

const theme = createTheme({
  overrides: {
    MuiTextField: {
      root: {
        backgroundColor: 'green',
      },
    },
  },
});

const colorStyles = {
  dropdownIndicator: base => ({
    ...base,
    color: '#667085', // Custom color
  }),
  placeholder: base => ({
    ...base,
    color: '#667085',
  }),
};

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    console.log('Props =>', props);
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = {
      selectedLocations: [],
      uploadDelay: false,
      is_initial: false,
      activeStep: 0,
      handleSubmitFunc: null,
      showPaymentMethodPage: false,
      userType: '',
      phoneNumber: '',
      provider: this.props?.currentUser?.attributes?.stripeConnected,
      customer: false,
      selectedOption: null,
      citiesArray: [
        { id: 1, value: 'Miami', label: 'Miami' },
        { id: 2, value: 'Fort Lauderdale', label: 'Fort Lauderdale' },
        { id: 3, value: 'West Palm Beach', label: 'West Palm Beach' },
        { id: 4, value: 'Naples', label: 'Naples' },
        { id: 5, value: 'Fresno', label: 'Fresno' },
        { id: 6, value: 'Atlanta', label: 'Atlanta' },
        { id: 7, value: 'Austin', label: 'Austin' },
        { id: 8, value: 'Detroit', label: 'Detroit' },
        { id: 9, value: 'Dallas/Fort Worth', label: 'Dallas/Fort Worth' },
        { id: 10, value: 'Kansas', label: 'Kansas' },
        { id: 11, value: 'Orlando', label: 'Orlando' },
        { id: 12, value: 'Hartford', label: 'Hartford' },
        { id: 13, value: 'Philadelphia', label: 'Philadelphia' },
        { id: 14, value: 'Tampa', label: 'Tampa' },
        { id: 15, value: 'Houston', label: 'Houston' },
        { id: 16, value: 'Sarasota', label: 'Sarasota' },
        { id: 17, value: 'Omaha', label: 'Omaha' },
        { id: 18, value: 'Fredrick', label: 'Fredrick' },
        { id: 19, value: 'Maryland', label: 'Maryland' },
        { id: 20, value: 'Knoxville', label: 'Knoxville' },
      ],
    };
    this.submittedValues = {};
    this.myRef = React.createRef();
  }

  componentDidMount() {
    let selectedLocations = [];
    const user = ensureCurrentUser(this.props?.currentUser);
    for (let i = 0; i < user?.attributes?.profile?.publicData?.city?.length; i++) {
      let params = {};

      params['value'] = user?.attributes?.profile?.publicData?.city[i].name;
      params['id'] = user?.attributes?.profile?.publicData?.city[i].name;
      params['label'] = user?.attributes?.profile?.publicData?.city[i].name;
      selectedLocations.push(params);
    }

    if (this.props.userType) {
      this.setState({ userType: this.props.userType });
    }
    if (this.props.currentUser) {
      this.setState({ phoneNumber: user?.attributes?.profile?.publicData?.phoneNumber });
      this.setState({
        selectedOption: selectedLocations,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('stripeFormDetails');
    window.clearTimeout(this.uploadDelayTimeoutId);
  }
  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return 'Select campaign settings...';
      case 1:
        return 'What is an ad group anyways?';
      case 2:
        return 'This is the bit I really care about!';
      default:
        return 'Unknown stepIndex';
    }
  }

  handleChange = event => {
    if (event == 'customer' || event == 'provider') {
      this.setState({ userType: event });
      this.props.setselectedUserType(event);
    } else {
      this.setState({ userType: event.target.value });
      this.props.setselectedUserType(event.target.value);
    }
  };

  handleUserType = user => {
    this.setState({ [user]: !this.state[user] });
    if (user == 'provider') {
      this.setState({ customer: false });
    } else {
      this.setState({ provider: false });
    }
  };

  handleCityChange = (selectedOption,selectedItem) => {
     console.log('selectedOption',selectedOption,selectedItem)
    this.setState({ selectedOption:[selectedItem.option] });
    this.props.setSelected([selectedItem.option]);
  };

  handleDelete = id => {
    const deletedItem = this.state.selectedOption.filter(item => item.id !== id);
    this.setState({ selectedOption: deletedItem });
    console.log(deletedItem, 'deletedItem');

    this.props.setSelected(deletedItem);
  };

  render() {
    console.log('isProfileSubmitted', this.props.isProfileSubmitted);
    const { selectedOption } = this.state;
    const { currentTab, handleScreenChange } = this.props;
    return (
      <FinalForm
        {...this.props}
        onSubmit={e => {
          e['phoneNumber'] = this.state.phoneNumber;
          this.props.onSubmit(e);
        }}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Bio
          const bioLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.bioLabel',
          });
          const bioPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.bioPlaceholder',
          });

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}></div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.changeAvatar}></div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          const [options, setOption] = useState([
            { name: 'Miami', id: 'Miami' },
            { name: 'Fort Lauderdale', id: 'Fort Lauderdale' },
            { name: 'West Palm Beach', id: 'West Palm Beach' },
            { name: 'Naples', id: 'Naples' },
            { name: 'Fresno', id: 'Fresno' },
            { name: 'Atlanta', id: 'Atlanta' },
            { name: 'Austin', id: 'Austin' },
            { name: 'Detroit', id: 'Detroit' },
            { name: 'Dallas/Fort Worth', id: 'Dallas' },
            { name: 'Kansas', id: 'Kansas' },
            { name: 'Orlando', id: 'Orlando' },
            { name: 'Hartford', id: 'Hartford' },
            { name: 'Philadelphia', id: 'Philadelphia' },
            { name: 'Houston', id: 'Houston' },
            { name: 'Sarasota', id: 'Sarasota' },
            { name: 'Omaha', id: 'Omaha' },
            { name: 'Fredrick', id: 'Fredrick' },
            { name: 'Maryland', id: 'Maryland' },
            { name: 'Knoxville', id: 'Knoxville' },
          ]);

          const onSelect = (selectedList, selectedItem) => {
            this.setState({ citiesArray: [selectedItem] });
            this.props.setSelected([selectedItem]);
          };

          const onRemove = (selectedList, removedItem) => {
            this.props.setSelected({ selectedList });
          };
          //  if (this.props?.selectedList?.length == 0) {
          //     this.props.setSelected(user?.attributes?.profile?.publicData.city);
          // }

          const phoneLabel = intl.formatMessage({
            id: 'SignupForm.phoneLabel',
          });
          const phonePlaceholder = intl.formatMessage({
            id: 'SignupForm.phonePlaceholder',
          });
          const phoneRequiredMessage = intl.formatMessage({
            id: 'SignupForm.phoneRequired',
          });
          const phoneRequired = validators.required(phoneRequiredMessage);
          const [value, setValue] = useState();
          const [valuechosen, setValueChosen] = useState(false);

          const [phoneNumberError, setNumberError] = useState();
          const requireImage = value => (value ? undefined : 'Required');
          const requireBio = value => (value ? undefined : 'Required');

          return (
            <Fragment>
              <Box sx={{}}>
                <Container maxWidth="sm" style={{ maxWidth: '650px' }}>
                  <Grid container style={{ background: 'transparent' }}>
                    <Form
                      className={classes}
                      onSubmit={e => {
                        this.submittedValues = values;

                        handleSubmit(e);
                      }}
                      style={{ width: '100%' }}
                    >
                      <>
                        {currentTab === 'profile' && (
                          <>
                            {/* <h1
                              style={{
                                fontSize: '30px',
                                fontWeight: '600',
                                color: '#101828',
                                textAlign: 'center',
                              }}
                            >
                              <FormattedMessage id="ProfileSettingsPage.heading2" />
                            </h1> */}
                            <div className={css.sectionContainer + 1}>
                              <Field
                                accept={ACCEPT_IMAGES}
                                id="profileImage"
                                name="profileImage"
                                label={chooseAvatarLabel}
                                type="file"
                                form={null}
                                uploadImageError={uploadImageError}
                                disabled={uploadInProgress}
                                validate={requireImage}
                              >
                                {fieldProps => {
                                  const {
                                    accept,
                                    id,
                                    input,
                                    meta,
                                    label,
                                    disabled,
                                    uploadImageError,
                                  } = fieldProps;
                                  const { name, type } = input;
                                  const onChange = e => {
                                    const file = e.target.files[0];
                                    form.change(`profileImage`, file);
                                    form.blur(`profileImage`);
                                    if (file != null) {
                                      const tempId = `${file.name}_${Date.now()}`;
                                      onImageUpload({ id: tempId, file });
                                    }
                                  };

                                  let error = null;

                                  if (isUploadImageOverLimitError(uploadImageError)) {
                                    error = (
                                      <div className={css.error}>
                                        <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                                      </div>
                                    );
                                  } else if (uploadImageError) {
                                    error = (
                                      <div className={css.error}>
                                        <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                                      </div>
                                    );
                                  }
                                  return (
                                    <div className={css.uploadAvatarWrapper}>
                                      <label className={css.label} htmlFor={id}>
                                        {label}
                                      </label>
                                      <input
                                        accept={accept}
                                        id={id}
                                        name={name}
                                        className={css.uploadAvatarInput}
                                        disabled={disabled}
                                        onChange={onChange}
                                        type={type}
                                      />
                                      <div className={css.error}>
                                        {' '}
                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                      </div>
                                      {error}
                                    </div>
                                  );
                                }}
                              </Field>
                            </div>
                            <Grid item xs={12} sm={12} md={12}>
                              <Box sx={{}}>
                                <FieldTextInput
                                  style={{
                                    border: '1px solid #D0D5DD',
                                    borderRadius: '8px',
                                    marginBottom: '24px',
                                    backgroundColor: 'white',
                                  }}
                                  validate={requireBio}
                                  type="textarea"
                                  name="bio"
                                  id="bio"
                                  rows="5"
                                  label={bioLabel}
                                  placeholder={bioPlaceholder}
                                />
                              </Box>
                            </Grid>
                            <br />
                            <Grid item xs={12} sm={12} md={12}>
                              <Box sx={{ width: '100%' }}>
                                <label
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#344054',
                                    marginBottom: '6px',
                                  }}
                                >
                                  Locations
                                </label>
                                <ReactSelect
                                  // selectedValues={
                                  //   this.state.is_initial === true &&
                                  //   this.props?.selectedList.length > 0
                                  //     ? this.props?.selectedList
                                  //     : user?.attributes?.profile?.publicData.city
                                  // }
                                  styles={colorStyles}
                                  controlShouldRenderValue={false}
                                  placeholder="Search and select your location"
                                  isClearable={false}
                                  isMulti={true}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  options={this.state.citiesArray}
                                  value={selectedOption}
                                  onChange={this.handleCityChange}
                                  onSelect={(selectedList, selectedItem) =>
                                    onSelect(selectedList, selectedItem)
                                  }
                                  name="city"
                                />
                                {this.state.selectedOption?.length > 0 ? (
                                  <div>
                                    <p
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: '#667085',
                                      }}
                                    >
                                      Selected Locations
                                    </p>
                                    {this.state.selectedOption.map(option => (
                                      <ul
                                        key={option.id}
                                        style={{
                                          background: '#F9FAFB',
                                          height: '50px',
                                          padding: '10px 16px 10px 16px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <li
                                          style={{
                                            color: '#344054',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                          }}
                                        >
                                          {option.value}
                                        </li>
                                        <div onClick={() => this.handleDelete(option.id)}>
                                          <img src={DeleteIcon} />
                                        </div>
                                      </ul>
                                    ))}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </Box>
                            </Grid>
                            <br />
                            <Box className={css.phone}>
                              <label
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#344054',
                                  marginBottom: '6px',
                                }}
                              >
                                {phoneLabel}
                              </label>
                              <PhoneInput
                                style={{
                                  border: '1px solid #D0D5DD',
                                  borderRadius: '8px',
                                  padding: '10px 14px',
                                  marginBottom: '24px',
                                  backgroundColor: 'white',
                                }}
                                placeholder={
                                  user?.attributes?.profile?.publicData?.phoneNumber
                                    ? user?.attributes?.profile?.publicData?.phoneNumber
                                    : phonePlaceholder
                                }
                                value={
                                  this.state.phoneNumber || valuechosen
                                    ? this.state.phoneNumber
                                    : user?.attributes?.profile?.publicData?.phoneNumber
                                }
                                name="phoneNumber"
                                validate={phoneRequired}
                                defaultCountry="US"
                                rules={{ required: true }}
                                onChange={value => {
                                  this.setState({
                                    phoneNumber: value,
                                  });
                                  setValueChosen(true);
                                }}
                              />
                              <p className="error" style={{ color: 'red' }}>
                                {phoneNumberError}
                              </p>
                            </Box>
                            <FormControl className={css.formControl}>
                              <label
                                style={{
                                  marginBottom: '6px',
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#344054',
                                }}
                              >
                                User type
                              </label>
                              <ThemeProvider theme={theme}>
                                <Select
                                  style={{
                                    border: '1px solid #D0D5DD',
                                    borderRadius: '8px',
                                    padding: '5px 14px',
                                    backgroundColor: 'white',
                                    margin: 0,
                                  }}
                                  value={
                                    this.state.userType !== ''
                                      ? this.state.userType
                                      : user?.attributes?.profile?.publicData.usertype
                                  }
                                  onChange={event => this.handleChange(event)}
                                  displayEmpty
                                  name="usertype"
                                >
                                  <MenuItem disabled value="">
                                    <em>Select user type</em>
                                  </MenuItem>
                                  <MenuItem value={'customer'}>Customer</MenuItem>
                                  <MenuItem value={'provider'}>Provider</MenuItem>
                                </Select>
                              </ThemeProvider>
                              {/* <FormHelperText>Required</FormHelperText> */}
                            </FormControl>{' '}
                          </>
                        )}
                        {currentTab === 'musician' && (
                          <>
                            {/* <p
                              style={{
                                color: '#344054',
                                fontSize: '14px',
                                marginBottom: '6px',
                                fontWeight: 500,
                              }}
                            >
                              How will you use worship serve?
                            </p> */}

                            <Paper
                              elevation={4}
                              style={{
                                padding: '24px',
                                marginBottom: '6px',
                                border: '1px solid #EAECF0',
                                boxShadow:
                                  '0px 2px 4px -1px rgb(0 0 0 /1%), 0px 4px 5px 0px rgb(0 0 0 / 1%), 0px 1px 10px 0px rgb(0 0 0 / 1%)',
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Switch
                                  color="primary"
                                  checked={this.state.provider}
                                  style={{ color: this.state.provider ? '#8C52FF ' : '' }}
                                  onChange={() => {
                                    this.handleUserType('provider');
                                  }}
                                  // style={{ backgroundColor: '#7F56D9' }}
                                  // style={{ color: this.state.checked && 'yellow' }}
                                />
                                <span
                                  style={{
                                    paddingLeft: '8px',
                                    lineHeight: '20px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                  }}
                                >
                                  I’m a Musician Available to Serve
                                </span>
                              </div>
                              <span
                                style={{
                                  paddingLeft: '66px',
                                  lineHeight: '20px',
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  // color: '#667085',
                                }}
                              >
                                I’m open and available for opportunities
                              </span>
                              {this.state.provider && (
                                <StripePayoutPage
                                  hide={true}
                                  ref={this.myRef}
                                  Submit={value => Submit(value)}
                                  isProviderFormEnabled={this.state.provider}
                                  isProfileSubmitted={this.props.isProfileSubmitted}
                                />
                              )}
                            </Paper>
                            {this.state.provider && (
                              <div className={css.stripeContainerInfo}>
                                <div className={css.iconImg}>
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ fill: 'none', transform: 'translateY(-25%)' }}
                                  >
                                    <g clip-path="url(#clip0_1547_35539)">
                                      <path
                                        d="M9.99984 14.2637V10.9303M9.99984 7.59699H10.0082M18.3332 10.9303C18.3332 15.5327 14.6022 19.2637 9.99984 19.2637C5.39746 19.2637 1.6665 15.5327 1.6665 10.9303C1.6665 6.32795 5.39746 2.59698 9.99984 2.59698C14.6022 2.59698 18.3332 6.32795 18.3332 10.9303Z"
                                        stroke="#7F56D9"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1547_35539">
                                        <rect
                                          width="20"
                                          height="20"
                                          fill="white"
                                          transform="translate(0 0.930328)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                                <div className={css.stripeContent}>
                                  <p className={css.stripeHeading}>What is Stripe Connect?</p>
                                  <p className={css.stripeInfo}>
                                    Stripe Connect is a third-party service provider that manages
                                    your payments from WorshipServe. WorshipServe does not manage or
                                    store your bank or payment information.
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {currentTab === 'church' && (
                          <>
                            <Paper
                              elevation={4}
                              style={{
                                padding: '24px',
                                marginBottom: '24px',
                                borderRadius: '8px',
                                border: '1px solid #EAECF0',
                                boxShadow:
                                  '0px 2px 4px -1px rgb(0 0 0 /1%), 0px 4px 5px 0px rgb(0 0 0 / 1%), 0px 1px 10px 0px rgb(0 0 0 / 1%)',
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Switch
                                  color="primary"
                                  checked={this.state.customer}
                                  style={{
                                    color: this.state.customer ? '#8C52FF ' : '',
                                  }}
                                  onChange={() => {
                                    this.handleUserType('customer');
                                  }}
                                />
                                <span
                                  style={{
                                    paddingLeft: '8px',
                                    lineHeight: '20px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                  }}
                                >
                                  I’m a Church Looking to Hire Musicians
                                </span>
                              </div>
                              <span
                                style={{
                                  paddingLeft: '66px',
                                  lineHeight: '20px',
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: '#667085',
                                }}
                              >
                                I’m looking to offer others opportunities
                              </span>
                              {this.state.customer && (
                                <PaymentMethodsPage
                                  isProfileSubmitted={this.props.isProfileSubmitted}
                                  hide={true}
                                  isCustomerFormEnabled={this.state.customer}
                                />
                              )}
                            </Paper>
                          </>
                        )}
                        {/* <br /> */}
                      </>

                      {/* <br />
                      <br /> */}
                      <Box sx={{ marginTop: '32px', display: 'flex', gap: 10 }}>
                        <Button
                          style={{
                            borderRadius: '4px',
                            backgroundColor: 'white',
                            color: '#344054',
                            border: '1px solid #D0D5DD',
                          }}
                          onClick={e => {
                            e.preventDefault();
                            handleScreenChange();
                          }}
                        >
                          Back
                        </Button>

                        <Button
                          style={{ borderRadius: '4px' }}
                          inProgress={submitInProgress}
                          onClick={e => {
                            console.log('empty', e, value);
                            handleSubmit(e, value);
                          }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Form>
                  </Grid>
                </Container>
              </Box>
            </Fragment>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;

// import React, { Fragment } from 'react';
// import { Box, Container, Grid, Typography } from '@material-ui/core';
// const ProfileSettingsForm = () => {
//   return (
//     <Fragment>
//       <Box>
//         <Container maxWidth="sm">
//           <Grid container>
//             <Grid item xs={12} sm={12} md={12}>
//               <Typography variant="h4">Profile Setting Page</Typography>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//     </Fragment>
//   );
// };

// export default ProfileSettingsForm;
