import React from 'react'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import MenuIcon from '@material-ui/icons/Menu';
import InboxIcon from '@material-ui/icons/Inbox';
import { useHistory } from 'react-router-dom';
import './LandingPageMain.css'



const BottomNavigationLanding = (props) => {
    const [value, setValue] = React.useState('recents');
    const history = useHistory()
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === "rewards") {
            history.push('/inbox/share')
        }
        else if (newValue === "inbox") {
            history.push('/inbox/orders')
        }
        else if (newValue === "search") {
            history.push('/?mobilesearch=open')
        }
        else if (newValue === "menu") {
            history.push('?mobilemenu=open')
        } else if (newValue === "favorites") {
            props?.setshowMobile(!props?.showMobile);
        }
    };
    return (
        <>
            <BottomNavigation showLabels value={value} onChange={handleChange} className={"BottomNavigation_class"} >

                <BottomNavigationAction label="Search" value="search" icon={<SearchIcon />} />
                <BottomNavigationAction label="Favorites" value="favorites" icon={<FavoriteIcon />} />
                <BottomNavigationAction label="Rewards" value="rewards" icon={<CardGiftcardIcon />} />
                <BottomNavigationAction label="Inbox" value="inbox" icon={<InboxIcon />} />
                <BottomNavigationAction value="menu" icon={<MenuIcon />} />
            </BottomNavigation>
        </>
    )
}
export default BottomNavigationLanding