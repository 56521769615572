import React, { Component, useEffect, useState } from 'react';
import { array, string, func, number } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '..';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SmsIcon from '@material-ui/icons/Sms';
import StarRateIcon from '@material-ui/icons/StarRate';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import css from './ListingCard.module.css';
import { fetchReviews } from '../../containers/ListingPage/ListingPage.duck';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SectionReviews from '../../containers/ListingPage/SectionReviews';
import { capitalize } from 'lodash';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardMobileComponent = props => {
  const [state, setState] = useState([]);
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    data_panel,
    fetchReviewsFunc,
    reviews,
    fetchReviewsError,
  } = props;

  useEffect(() => {
    fetchReviewsFunc(listing.id.uuid, 'listpage')
      .then(response => {
        setState(response);
      })
      .catch(err => console.log(err));
  }, [listing.id.uuid]);

  // const listing = publicData
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  let checker = (arr, target) => target.every(v => arr.includes(v));

  let is_showlist = checker(currentListing?.attributes?.publicData?.yogaStyles, data_panel);

  let datavalue = currentListing?.attributes?.publicData?.yogaStyles
    .map(values => `${values}`)
    .join(',');




  function convertH2M(timeInHour) {
    var timeParts = timeInHour.split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  }

  var timeInMinutesArray = currentListing?.attributes?.publicData?.averageTime?.map((data) => { return convertH2M(data) });
  let sumofminitus = timeInMinutesArray?.reduce(function (acc, val) { return acc + val; }, 0)
  let avarageMinitus = sumofminitus / timeInMinutesArray?.length

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + Math.round(m) : Math.round(m);
    return `${h}:${m}`;
  }

  let avarageMinitusTime = "03:30"
  if (currentListing?.attributes?.publicData?.averageTime !== undefined && currentListing?.attributes?.publicData.averageTime?.length > 0) {
    avarageMinitusTime = convertMinsToHrsMins(avarageMinitus)
  }




  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';
  console.log("currentListing", currentListing.attributes)
  if (data_panel.length > 0) {

    if (is_showlist) {

      return (
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <div
            className={css.threeToTwoWrapper}
            onMouseEnter={() => setActiveListing(currentListing.id)}
            onMouseLeave={() => setActiveListing(null)}
          >
            <div className={css.aspectWrapper}>
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            </div>
          </div>
          <div className={`${css.info} custom_mainnn`}>
            <div className={`${css.mainInfo} custom_main_child_1`}>
              <div className={`${css.title} custom_title`}>
                {richText(`${title}- (${datavalue})   ${formattedPrice} / Hour`, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <div className={css.certificateInfo}>
                {certificate && !certificate.hideFromListingInfo ? (
                  <span>{certificate.label}</span>
                ) : null}
              </div>
              {/* <div className={`${css.priceValue} custome_price`} title={priceTitle}>
                {formattedPrice}
              </div> */}
              <div className="location_icon">
                <LocationOnIcon />
                <div>Based out of : {props?.listing?.attributes?.publicData?.location?.address}</div>
              </div>
              <div className="time_cons">
                <SmsIcon />
                Typically responds in: {avarageMinitusTime ? avarageMinitusTime : "00:00"} Hour
              </div>
              <div className={`${css.priceValue} custome_price`} title={priceTitle}>
                <div> <MonetizationOnIcon /> </div> {formattedPrice} / <FormattedMessage id={unitTranslationKey} />
              </div>
              <div className="review_details">
                <SectionReviews reviews={state} fetchReviewsError={fetchReviewsError} />

                {/* Reviews(0) */}
              </div>
            </div>
            <div className="ViewListion_class_div">
              <button
                className="button_listing_classs"
                style={{
                  backgroundColor: '#8c52ff',
                  color: '#fff',
                  borderRadius: '10px',
                  marginTop: '151px',
                  marginLeft: '50px',
                }}
              >
                View listing
              </button>
            </div>
          </div>
        </NamedLink>
      );
    } else {
      // props.available_list = props.available_list - 1
      return null;
    }
  } else {
    return (
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
          </div>
        </div>
        <div className={`${css.info} custom_main`}>
          <div className={`${css.mainInfo} custom_main_child_1`}>
            <div className={`${css.title} custom_title`}>
              {/* - (${datavalue}) ${formattedPrice} / Hour */}
              {richText(`${title}`, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            <div className={css.certificateInfo}>
              {certificate && !certificate.hideFromListingInfo ? (
                <span>{certificate.label}</span>
              ) : null}
            </div>


            <div className="location_icon align-items-start">
              <LocationOnIcon />
              <div>
                Based out of : {props?.listing?.attributes?.publicData?.location?.address}
              </div>
            </div>
            <div className="time_cons">
              <SmsIcon />
              Typically responds in: {avarageMinitusTime ? avarageMinitusTime : "00:00"} Hour
            </div>
            <div className={`${css.priceValue} custome_price`} title={priceTitle}>
              <div> <MonetizationOnIcon /> </div> {formattedPrice} / <FormattedMessage id={unitTranslationKey} />
            </div>
            <div className="review_details">
              <SectionReviews reviews={state} fetchReviewsError={fetchReviewsError} />
              {/* Reviews(0) */}
            </div>
          </div>

          <div className="ViewListion_class_div w_100_m">
            <button
              className="button_listing_classs"
              style={{
                backgroundColor: '#8c52ff',
                color: '#fff',
                fontWeight: 700,
                textTransform: capitalize,
                borderRadius: '10px',
                marginTop: '151px',
                marginLeft: '50px',
                padding: '12px 10px'
              }}
            >
              View listing
            </button>
          </div>
        </div>
      </NamedLink>
    );
  }
};

ListingCardMobileComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
  data_panel: null,
  reviews: [],
  fetchReviewsError: null,
  fetchReviewsFunc: () => null,
};

ListingCardMobileComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  data_panel: array,
  // Responsive image sizes hint
  renderSizes: string,
  available_list: number,
  reviews: array,
  fetchReviewsError: propTypes.error,
  setActiveListing: func,
  fetchReviewsFunc: func,
};

const mapStateToProps = state => {
  const { reviews, fetchReviewsError } = state.ListingPage;
  return { reviews, fetchReviewsError };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchReviewsFunc: (id, section) => dispatch(fetchReviews(id, section)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingCardMobileComponent);
