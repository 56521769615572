import React, { Component, useEffect, useState } from 'react';
import { array, string, func, number } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SmsIcon from '@material-ui/icons/Sms';
import StarRateIcon from '@material-ui/icons/StarRate';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import css from './ListingCard.module.css';
import { fetchReviews, wishlistlisting } from '../../containers/ListingPage/ListingPage.duck';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SectionReviews from '../../containers/ListingPage/SectionReviews';
import { capitalize } from 'lodash';
import { isMobile } from 'react-device-detect';
import StarIcon from '@material-ui/icons/Star';
import Box from '@material-ui/core/Box';
import { Rating } from '@material-ui/lab';
import PersonIcon from '@material-ui/icons/Person';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import './custom.css';
import _ from 'lodash';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { authInfo } from '../../ducks/Auth.duck';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });
export const ListingCardComponent = props => {
  const [state, setState] = useState([]);
  const [totalAvrage, setTotalAvrage] = useState(null);
  const [totalReview, setTotalReview] = useState(0);
  const [wishListValue, setWishListValue] = useState([]);
  const [iconDisplayColor, setIconDisplayColor] = useState(false);

  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    data_panel,
    fetchReviewsFunc,
    reviews,
    fetchReviewsError,
    getSortAllData,
    totalSortingData,
  } = props;

  useEffect(() => {
    fetchReviewsFunc(listing.id.uuid, 'listpage')
      .then(response => {
        setState(response);
        let rating1 = 0;
        let rating2 = 0;
        let rating3 = 0;
        let rating4 = 0;
        let rating5 = 0;
        response?.map(values => {
          switch (values?.attributes?.rating) {
            case 1:
              rating1 += 1;
              break;
            case 2:
              rating2 += 1;
              break;
            case 3:
              rating3 += 1;
              break;
            case 4:
              rating4 += 1;
              break;
            case 5:
              rating5 += 1;
              break;
          }
        });
        let totalReview = rating5 + rating4 + rating3 + rating2 + rating1;
        // let dataFilter = [{ ...listing,{ rating: totalReview }}];
        getSortAllData('sort_rating', { ...listing, ...{ rating: totalReview } });
        // if (totalSortingData === "sort_rating") {
        setTotalReview(totalReview);
        // }
        let totalAvrage =
          (5 * rating5 + 4 * rating4 + 3 * rating3 + 2 * rating2 + 1 * rating1) / totalReview;
        if (totalAvrage === 0 || totalAvrage) {
          setTotalAvrage(parseFloat(totalAvrage).toFixed(1));
        }
      })
      .catch(err => console.log(err));

    // props?.fetchCurrent();
  }, [listing.id.uuid]);

  useEffect(() => {
    let data = props?.currentUser?.attributes?.profile?.publicData?.favrate_list || [];
    if (data) {
      let color = data?.includes(String(listing?.id?.uuid)) ? true : false;
      setIconDisplayColor(color);
      setWishListValue(data);
    }
  }, [props?.currentUser]);

  function getUpdatedData(values) {
    props?.fetchCurrentUser();
  }
  // const listing = publicData
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  let checker = (arr, target) => target.every(v => arr && arr.includes(v));

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(props?.currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === props?.currentUser.id.uuid;
  let is_showlist = checker(currentListing?.attributes?.publicData?.yogaStyles, data_panel);

  let datavalue = currentListing?.attributes?.publicData?.yogaStyles&&currentListing.attributes.publicData.yogaStyles.map(values => `${values}`).join(',');

  function convertH2M(timeInHour) {
    var timeParts = timeInHour.split(':');
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  }

  // const isOwnListing = (props?.currentUser?.id?.uuid === listing.id.uuid) ? true : false;

  var timeInMinutesArray = currentListing?.attributes?.publicData?.averageTime?.map(data => {
    return convertH2M(data);
  });
  let sumofminitus = timeInMinutesArray?.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  let avarageMinitus = sumofminitus / timeInMinutesArray?.length;

  const convertMinsToHrsMins = mins => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + Math.round(m) : Math.round(m);
    return `${h}:${m}`;
  };

  const rand = Math.floor(Math.random() * 11);

  let avarageMinitusTime = rand;
  if (
    currentListing?.attributes?.publicData?.averageTime !== undefined &&
    currentListing?.attributes?.publicData.averageTime?.length > 0
  ) {
    avarageMinitusTime = convertMinsToHrsMins(avarageMinitus);
  }

  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  let UPDATE_PRICE = null;
  if ('servicePrice' in publicData) {
    // UPDATE_PRICE = {
    //   amount: publicData?.servicePrice ? +publicData?.servicePrice[data_panel[0]] : 0,
    //   currency: "USD",
    //   Type: "Money",
    // }
    UPDATE_PRICE = publicData?.servicePrice ? +publicData?.servicePrice[data_panel[0]] : 0;
  }
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  let changeData = [];
  // console.log('currentListing', currentListing?.attributes?.publicData?.city);

  if (data_panel.length > 0) {
    if (is_showlist) {
      
      return (
        <div className="mainContainer">
          {/* {totalAvrage !== null && */}
          <div className={classes}>
            <div
              className={`${css.threeToTwoWrapper} threeToTwoWrapper `}
              onMouseEnter={() => setActiveListing(currentListing.id)}
              onMouseLeave={() => setActiveListing(null)}
            >
              <div className={`${css.aspectWrapper} custom_image`}>
                <LazyImage
                  rootClassName={css.rootForImage}
                  alt={title}
                  image={firstImage}
                  variants={['landscape-crop', 'landscape-crop2x']}
                  sizes={renderSizes}
                />
              </div>
            </div>
            <div className={`${css.info} custom_main`}>
              <div className={`${css.mainInfo} custom_main_child_1`}>
                <div className={`${css.title} custom_title`}>
                  <h2>
                    {richText(`${title}`, {
                      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                      longWordClass: css.longWord,
                    })}
                  </h2>
                  {!isOwnListing && props?.isAuthenticated && (
                    <div className="star_icon_custom">
                      <StarIcon
                        htmlColor={iconDisplayColor ? '#ffb019' : ''}
                        onClick={() => {
                          if (_.find(wishListValue, row => row === listing?.id?.uuid)) {
                            changeData = _.reject(wishListValue, row => row === listing?.id?.uuid);
                            setWishListValue(changeData);
                            setIconDisplayColor(false);
                          } else {
                            changeData = [...wishListValue, listing?.id?.uuid];
                            setWishListValue(changeData);
                            setIconDisplayColor(true);
                          }
                          props?.onWishListListing(changeData);
                          // props?.fetchCurrent();

                          setTimeout(() => {
                            getUpdatedData(changeData);
                          }, 3000);
                        }}
                      />
                    </div>
                  )}
                  {!isMobile && (
                    <div
                      className={`${css.priceValue} custome_price custom_price_mobile`}
                      title={priceTitle}
                    >
                      <div className="price_main"> </div> {UPDATE_PRICE ? `$${parseFloat(+UPDATE_PRICE).toFixed(2)}` : formattedPrice} /{' '}
                      <FormattedMessage id={unitTranslationKey} />
                    </div>
                  )}
                </div>

                <div className={css.certificateInfo}>
                  {certificate && !certificate.hideFromListingInfo ? (
                    <span>{certificate.label}</span>
                  ) : null}
                </div>

                <div className="star_icon_review">
                  <span className="review_qty">{totalAvrage}</span>
                  <Rating
                    name="disabled"
                    value={totalAvrage}
                    disabled
                    size="large"
                    precision={0.5}
                  />
                  <span>
                    (<PersonIcon /> {totalReview})
                  </span>
                </div>

                <div className="location_icon align-items-start">
                  <LocationOnIcon />
                  <div>
                    Based out of : {props?.listing?.attributes?.publicData?.location?.address}
                  </div>
                </div>
                <div className="time_cons">
                  <SmsIcon />
                  Typically responds in:{' '}
                  <span className="csnt-bold">
                    {avarageMinitusTime ? avarageMinitusTime : '00:00'} Hour
                  </span>
                </div>
                <div className="musicstyle_button">
                  {props?.listing?.attributes?.publicData?.musicStyles?.map(data => {
                    const musicListArray = {
                      contemporary_christianc_music: 'Contemporary Christian Music',
                      charismatic: 'Charismatic',
                      gospel: 'Gospel',
                      traditional: 'Traditional',
                    };
                    return (
                      <div className="music_btn_name_listing_card">
                        <MusicNoteIcon />
                        {musicListArray[data]}
                      </div>
                    );
                  })}
                </div>
              </div>

              {!isMobile && (
                <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
                  <div className="ViewListion_class_div w_100_m">
                    <button
                      className="button_listing_classs"
                      style={{
                        backgroundColor: '#8c52ff',
                        color: '#fff',
                        fontWeight: 700,
                        textTransform: capitalize,
                        borderRadius: '10px',
                        //   marginTop: '151px',
                        marginLeft: '50px',
                        padding: '12px 10px',
                      }}
                    >
                      View listing
                    </button>
                  </div>
                </NamedLink>
              )}
            </div>
            {isMobile && (
              <div className={`${css.priceValue} custome_price`} title={priceTitle}>
                <div>
                  {' '}
                  <MonetizationOnIcon />{' '}
                </div>{' '}
                {UPDATE_PRICE ? `$${parseFloat(+UPDATE_PRICE).toFixed(2)}` : formattedPrice} / <FormattedMessage id={unitTranslationKey} />
              </div>
            )}
            {isMobile && (
              <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
                <div className="ViewListion_class_div w_100_m">
                  <button
                    className="button_listing_classs"
                    style={{
                      backgroundColor: '#8c52ff',
                      color: '#fff',
                      fontWeight: 700,
                      textTransform: capitalize,
                      borderRadius: '10px',
                      marginTop: '151px',
                      marginLeft: '50px',
                      padding: '12px 10px',
                    }}
                  >
                    View listing
                  </button>
                </div>
              </NamedLink>
            )}
          </div>

          {/* } */}
        </div>
      );
    } else {
      // props.available_list = props.available_list - 1
      return null;
    }
  } else {
    return (
      <div className="mainContainer">
        {/* {totalAvrage !== null && */}
        <div className={classes}>
          <div
            className={`${css.threeToTwoWrapper} threeToTwoWrapper `}
            onMouseEnter={() => setActiveListing(currentListing.id)}
            onMouseLeave={() => setActiveListing(null)}
          >
            <div className={`${css.aspectWrapper} custom_image`}>
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            </div>
          </div>
          <div className={`${css.info} custom_main`}>
            <div className={`${css.mainInfo} custom_main_child_1`}>
              <div className={`${css.title} custom_title`}>
                <h2>
                  {richText(`${title}`, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,
                  })}
                </h2>
                {!isOwnListing && props?.isAuthenticated && (
                  <div className="star_icon_custom">
                    <StarIcon
                      htmlColor={iconDisplayColor ? '#ffb019' : ''}
                      onClick={() => {
                        if (_.find(wishListValue, row => row === listing?.id?.uuid)) {
                          changeData = _.reject(wishListValue, row => row === listing?.id?.uuid);
                          setWishListValue(changeData);
                          setIconDisplayColor(false);
                        } else {
                          changeData = [...wishListValue, listing?.id?.uuid];
                          setWishListValue(changeData);
                          setIconDisplayColor(true);
                        }
                        props?.onWishListListing(changeData);
                        setTimeout(() => {
                          getUpdatedData(changeData);
                        }, 3000);
                      }}
                    />
                  </div>
                )}

                {!isMobile && (
                  <div
                    className={`${css.priceValue} custome_price custom_price_mobile`}
                    title={priceTitle}
                  >
                    <div className="price_main"> </div> {UPDATE_PRICE ? `$${parseFloat(+UPDATE_PRICE).toFixed(2)}` : formattedPrice} /{' '}
                    <FormattedMessage id={unitTranslationKey} />
                  </div>
                )}
              </div>
              <div className="musicstyle_button">
                {props?.listing?.attributes?.publicData?.musicStyles?.map(data => {
                  const musicListArray = {
                    contemporary_christianc_music: 'Contemporary Christian Music',
                    charismatic: 'Charismatic',
                    gospel: 'Gospel',
                    traditional: 'Traditional',
                  };
                  return (
                    <div className="music_btn_name_listing_card">
                      <MusicNoteIcon />
                      {musicListArray[data]}
                    </div>
                  );
                })}
              </div>

              <div className={css.certificateInfo}>
                {certificate && !certificate.hideFromListingInfo ? (
                  <span>{certificate.label}</span>
                ) : null}
              </div>

              <div className="location_icon align-items-start">
                <LocationOnIcon />
                <div>
                  Based out of : {props?.listing?.attributes?.publicData?.location?.address}
                </div>
              </div>
              <div className="time_cons">
                <SmsIcon />
                Typically responds in:{' '}
                <span className="csnt-bold">
                  {avarageMinitusTime ? avarageMinitusTime : '00:00'} Hour
                </span>
              </div>
              <div className="star_icon_review">
                <span className="review_qty">{totalAvrage}</span>
                <Rating name="disabled" value={totalAvrage} disabled size="large" precision={0.5} />
                <span>
                  (<PersonIcon /> {totalReview})
                </span>
              </div>
            </div>

            {!isMobile && (
              <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
                <div className="ViewListion_class_div w_100_m">
                  <button
                    className="button_listing_classs"
                    style={{
                      backgroundColor: '#8c52ff',
                      color: '#fff',
                      fontWeight: 700,
                      textTransform: capitalize,
                      borderRadius: '10px',
                      //   marginTop: '151px',
                      marginLeft: '50px',
                      padding: '12px 10px',
                    }}
                  >
                    View listing
                  </button>
                </div>
              </NamedLink>
            )}
          </div>
          {isMobile && (
            <div className={`${css.priceValue} custome_price`} title={priceTitle}>
              <div>
                {' '}
                <MonetizationOnIcon />{' '}
              </div>{' '}
              {UPDATE_PRICE ? `$${parseFloat(+UPDATE_PRICE).toFixed(2)}` : formattedPrice} / <FormattedMessage id={unitTranslationKey} />
            </div>
          )}
          {isMobile && (
            <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
              <div className="ViewListion_class_div w_100_m">
                <button
                  className="button_listing_classs"
                  style={{
                    backgroundColor: '#8c52ff',
                    color: '#fff',
                    fontWeight: 700,
                    textTransform: capitalize,
                    borderRadius: '10px',
                    marginTop: '151px',
                    marginLeft: '50px',
                    padding: '12px 10px',
                  }}
                >
                  View listing
                </button>
              </div>
            </NamedLink>
          )}
        </div>

        {/* } */}
      </div>
    );
  }
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
  data_panel: null,
  reviews: [],
  fetchReviewsError: null,
  // currentUser: null,
  isAuthenticated: null,
  fetchReviewsFunc: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  data_panel: array,
  // Responsive image sizes hint
  renderSizes: string,
  available_list: number,
  reviews: array,
  fetchReviewsError: propTypes.error,
  fetchReviewsFunc: func,
  setActiveListing: func,
  // currentUser: null,
  isAuthenticated: null,
};

const mapStateToProps = state => {
  const { reviews, fetchReviewsError } = state.ListingPage;
  // const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;

  return { reviews, fetchReviewsError, isAuthenticated };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchReviewsFunc: (id, section) => dispatch(fetchReviews(id, section)),
  onWishListListing: listingId => dispatch(wishlistlisting(listingId)),
  authenticationFunc: () => dispatch(authInfo()),
  // fetchCurrent: () => dispatch(fetchCurrentUser()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingCardComponent);
