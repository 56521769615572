import React from 'react';
import PropTypes from 'prop-types';
import Compnylogo from './logo.svg';
import companyLogo from './logo.png'
import mblLogo from './worship-favicon.png'

import MobileLogo from '../../../src/assets/logo2.svg';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (<>
    <img  className={className} src={companyLogo} alt="BigCo Inc. logo" style={{height:'42px',width:'auto',}}/>

    </>);
  }

  return (
    <img className={className} src={mblLogo} alt="BigCo Inc. logo" style={{height:'42px',width:'50px'}}/>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
