import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StartTimeComponent from './startTimeComponent';
import EndTimeComponent from './endTimeComponent';
import { MenuItem } from '@material-ui/core';
import './CustomEditListingPlan.css';

const useStyles = makeStyles(theme => ({
  Menuitem: {
    justifyContent: 'center',
  },
  active_list: {
    background: 'green',
  },
}));

export default function TimeDialog(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const {
    openTimeDialog,
    printHourStrings_hour,
    setOpenTimeDialog,
    sArrayStart,
    sArrayEnd,
    setDayWiseTime,
    dayOfWeek,
    index,
    onCancelTimeSelection,
    currentPeriods,
  } = props;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setOpenEndTime(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpenTimeDialog();
    setOpenTimeDialog(false);
  };

  const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = event => {
    setFullWidth(event.target.checked);
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openTimeDialog}
        onClose={() => {
          setOpenTimeDialog(false);
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Start Time" />
            <Tab label="End Time" />
          </Tabs>
          {value === 0 && (
            <>
              <ul className="starttime_ul">
                {sArrayStart?.map(s => {
                  return (
                    <MenuItem
                      className={`${classes.Menuitem} ${
                        currentPeriods?.startTime == s ? 'active_list' : 'not _active'
                      }`}
                      value={s}
                      key={s}
                      onClick={() => {
                        setDayWiseTime(s, 'startTime', dayOfWeek, index);
                      }}
                    >
                      {printHourStrings_hour(s)}
                    </MenuItem>
                  );
                })}
              </ul>
            </>
          )}
          {value === 1 && (
            <>
              <ul className="endtime_ul">
                {sArrayEnd.length > 0
                  ? sArrayEnd?.map(s => {
                      return (
                        <MenuItem
                          className={`${classes.Menuitem} ${
                            currentPeriods?.endTime == s ? 'active_list' : 'not _active'
                          }`}
                          value={s}
                          key={s}
                          onClick={() => {
                            setDayWiseTime(s, 'endTime', dayOfWeek, index);
                          }}
                        >
                          {printHourStrings_hour(s)}
                        </MenuItem>
                      );
                    })
                  : sArrayStart?.map(s => {
                      return (
                        <MenuItem
                          className={`${classes.Menuitem} ${
                            currentPeriods?.endTime == s ? 'active_list' : 'not _active'
                          }`}
                          value={s}
                          key={s}
                          onClick={() => {
                            setDayWiseTime(s, 'endTime', dayOfWeek, index);
                          }}
                        >
                          {printHourStrings_hour(s)}
                        </MenuItem>
                      );
                    })}
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="save"
            onClick={() => {
              onCancelTimeSelection();
              setOpenTimeDialog(false);
            }}
            color="primary"
          >
            Use these times
          </Button>
          <Button
            className="cancel"
            onClick={() => {
              //   onCancelTimeSelection();

              setOpenTimeDialog(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
