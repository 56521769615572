import React, { useState, useEffect } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingAvailabilityPanel,
  EditListingDescriptionPanel,
  EditListingFeaturesPanel,
  EditListingLocationPanel,
  EditListingPhotosPanel,
  EditListingPoliciesPanel,
  EditListingPricingPanel,
  EditListingVideosPanel,
} from '../../components';
import Multiselect from 'multiselect-react-dropdown';
import css from './EditListingWizard.module.css';
import { Box, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import Button from '../Button/Button';
import { CheckCircle } from '@material-ui/icons';
import './EditlistingCss.css';
import DoneIcon from '@material-ui/icons/Done';
import { updateUserToCustomerIO } from '../../util/api';
export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const MUSIC_STYLE = 'music_style';
export const FEATURES = 'features';
export const POLICY = 'policy';
export const LOCATION = 'location';
export const PRICING = 'pricing';
export const PHOTOS = 'photos';
export const VIDEOS = 'videos';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  DESCRIPTION,
  MUSIC_STYLE,
  FEATURES,
  POLICY,
  LOCATION,
  PRICING,
  AVAILABILITY,
  PHOTOS,
  VIDEOS
];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  console.log('hhhjkkkjkjuuuuuu');
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  console.log('params.type ',  params)
  console.log('LISTING_PAGE_PARAM_TYPE_NEW ',  LISTING_PAGE_PARAM_TYPE_NEW)
  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === 'LISTING_PAGE_PARAM_TYPE_NEW') {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    console.log('draftURI', draftURI)
    history.replace(draftURI);
  }

  // Redirect to next tab
  //  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  // const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  // console.log("to : " , to)
  // history.replace(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    fetchExceptionsInProgress,
    availabilityExceptions,
    onUpdateListingPublicDta,
  } = props;
  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  useEffect(() => {
    const hourly_rate = parseInt(currentListing?.attributes?.price?.amount) / 100;
    const firstName = currentListing?.author?.attributes?.profile?.firstName;
    const email = currentListing?.author?.attributes?.email;
    const lastName = currentListing?.author?.attributes?.profile?.lastName;
    const city = currentListing?.attributes?.publicData?.city;
    const services = currentListing?.attributes?.publicData?.yogaStyles;
    const listing_status = currentListing?.attributes?.state;
    const title = currentListing?.attributes?.title;
    const listingId = currentListing?.id?.uuid;
    const profile_url = `${window.location.origin}/l/${title}/${listingId}`;
    const thumbnail =
      currentListing?.images[0]?.attributes?.variants['landscape-crop2x']?.url || '';

    return () => {
      if (currentListing?.author?.id?.uuid) {
        const data = {
          id: currentListing?.author?.id?.uuid,
          listing_status,
          first_name: firstName,
          last_name: lastName,
          city,
          services,
          hourly_rate,
          email,
          profile_url,
          thumbnail,
        };
        updateUserToCustomerIO(data);
      }
    };
  });

  const onCompleteEditListingWizardTab = (tab, updateValues, passThrownErrors = false) => {
    // Normalize images for API call
    setactiveStep(currentactiveStep + 1);
    props.setCurrentActiveStep(currentactiveStep + 1)
    const { images: updatedImages, ...otherValues } = updateValues;
    console.log('updatedImages',{tab ,marketplaceTabs:marketplaceTabs[marketplaceTabs.length - 1]})
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== AVAILABILITY && tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);
            // After successful saving of draft data, user should be redirected to next tab
            redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            handlePublishListing(currentListing.id);
          }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      console.log('hello',{updateValuesWithImages})
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id });
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
    };
  };
  const [selectedList, setSelectedList] = useState([]);
  const [initialMusicActive, setInitailMusicActive] = useState(false);
  const [currentactiveStep, setactiveStep] = useState(0);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [submitMusicStyles, setSubmitMusicStyles] = useState(false);
  // useEffect(() => {
  //   console.log("history : ",history);
  //  if(currentactiveStep==9){
  //   window.location.href="/l/manage"
  //  }
  // },[currentactiveStep]);

  const options = [
    { name: 'Contemporary Christian Music', id: 'contemporary_christianc_music' },
    { name: 'Charismatic', id: 'charismatic' },
    { name: 'Gospel', id: 'gospel' },
    { name: 'Traditional', id: 'traditional' },
  ];
  const setSelected = data => {
    setSelectedList(data);
  };
  const onSelect = (selectedList, selectedItem) => {
    setSelected(selectedList);
    setInitailMusicActive(true);
    setSubmitMusicStyles(false);
  };
  const onRemove = (selectedList, removedItem) => {
    setSelected(selectedList);
    setInitailMusicActive(true);
    setSubmitMusicStyles(false);
  };
  const populateMusicStyles = () => {
    const { publicData } = currentListing?.attributes;
    const musicListArray = {
      contemporary_christianc_music: 'Contemporary Christian Music',
      charismatic: 'Charismatic',
      gospel: 'Gospel',
      traditional: 'Traditional',
    };
    let defaultseletedService = [];
    publicData?.musicStyles?.map(data => {
      if (data != '') {
        defaultseletedService.push({ id: data, name: musicListArray[data] });
      }
    });
    if (
      selectedList?.length === 0 &&
      publicData?.musicStyles?.length > 0 &&
      initialMusicActive === false
    ) {
      setSelectedList(defaultseletedService);
    }
  };
  const submitButtonTranslationKey = isNewListingFlow ? 'Next' : 'Next';
  if (params.tab != 'availability' && params.type !== 'edit') {
    return (
      <>
        {currentactiveStep === 0 ? (
          <Box className="main_title_page">
            <Box color="Purple" textAlign="center" className="main_title">
              <Typography variant="h3" className="listing_title_head">
                What is a listing?
              </Typography>
            </Box>
            <Box textAlign="center" className="mainHeading">
              A listing allows churches to find the services you provide on
            </Box>
            <Box textAlign="center" mb="40px" className="mainHeading1">
              the worshipserve platform.
            </Box>
            <Box textAlign="center" className="eachlisting">
              Each listing consists of:
            </Box>
            <Box className="list_points">
              <li>1. Who are you</li>
              <li>2. The type of services you provide</li>
              <li>3. The price of your services</li>
              <li>4. A description and video of your services</li>
            </Box>
            <Button
              onClick={() => {
                setactiveStep(currentactiveStep + 1);
                props.setCurrentActiveStep(currentactiveStep + 1)
              }}
              className="create listing point"
            >
              Create listings
            </Button>
          </Box>
        ) : (
          <Box>
            <Stepper activeStep={currentactiveStep}>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            
            {currentactiveStep === 2 && (
              <Box className="select_music_styles">
                <h1 className="music_style_heading">
                  Select the music styles you are comfortable playing
                </h1>
                <Box sx={{ display: 'flex', margin: '5px' }} className="button_collection">
                  <Multiselect
                    className="select_music_type"
                    options={options} // Options to display in the dropdown
                    selectedValues={
                      selectedList.length > 0 ? selectedList : populateMusicStyles() || []
                    }
                    // selectedValues={selectedList.length > 0 ? selectedList : []}

                    // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => onSelect(selectedList, selectedItem)} // Function will trigger on select event
                    onRemove={(selectedList, removedItem) => onRemove(selectedList, removedItem)} // Function will trigger on remove event
                    displayValue="name"
                    placeholder="Select Music Styles"
                    name="Music Style" // Property name to display in the dropdown options
                  />
                </Box>
                <Button
                  className="btn_save_music_style"
                  onClick={() => {
                    const musicStyles = selectedList?.map(value => value.id);
                    const updatedValues = {
                      publicData: { musicStyles },
                    };
                    onCompleteEditListingWizardTab(tab, updatedValues);
                    setactiveStep(currentactiveStep + 1);
                    props.setCurrentActiveStep(currentactiveStep + 1)
                  }}
                >
                  Save
                </Button>
              </Box>
            )}
            {currentactiveStep === 3 && (
              <Box className="select_listing_type">
                <h1>Select listing type</h1>
                <Box sx={{ display: 'flex', margin: '5px' }} className="button_collection">
                  <Button
                    type="button"
                    className={css.button_css}
                    onClick={() => {
                      setSelectedFeature(['worship_leader']);
                      setactiveStep(currentactiveStep + 1);
                      props.setCurrentActiveStep(currentactiveStep + 1)
                    }}
                  >
                    Worship leader
                  </Button>
                  <Button
                    type="button"
                    className={css.button_css}
                    onClick={() => {
                      setSelectedFeature([]);
                      setactiveStep(currentactiveStep + 1);
                      props.setCurrentActiveStep(currentactiveStep + 1)
                    }}
                  >
                    Musician
                  </Button>
                  <Button
                    type="button"
                    className={css.button_css}
                    onClick={() => {
                      setSelectedFeature(['tech']);
                      setactiveStep(currentactiveStep + 1);

                      props.setCurrentActiveStep(currentactiveStep + 1)
                    }}
                  >
                    Tech
                  </Button>
                </Box>
              </Box>
            )}
            {currentactiveStep === 1 && (
              <EditListingDescriptionPanel
                {...panelProps(DESCRIPTION)}
                submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                onSubmit={values => {
                  onCompleteEditListingWizardTab(tab, values);
                }}
              />
            )}

            {currentactiveStep === 4 && (
              <>
              <EditListingFeaturesPanel
                {...panelProps(FEATURES)}
                submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                onSubmit={values => {
                  onCompleteEditListingWizardTab(tab, values);
                }}
                selectedFeature={selectedFeature}
              />
              </>
            )}
            {currentactiveStep === 5 && (
              <div className="Steper_Custom">
                {' '}
                <EditListingLocationPanel
                  {...panelProps(LOCATION)}
                  submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                  onSubmit={values => {
                    console.log('values12', values);
                    // onUpdateListingPublicDta(listing.id,)
                    onCompleteEditListingWizardTab(tab, values);
                  }}
                />
              </div>
            )}
            {/* {currentactiveStep === 6 && (
              <EditListingPricingPanel
                {...panelProps(PRICING)}
                submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                onSubmit={values => {
                  onCompleteEditListingWizardTab(tab, values);
                }}
              />
            )} */}
            {currentactiveStep === 6 && (
              <EditListingAvailabilityPanel
                {...panelProps(AVAILABILITY)}
                fetchExceptionsInProgress={fetchExceptionsInProgress}
                availabilityExceptions={availabilityExceptions}
                submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                onAddAvailabilityException={onAddAvailabilityException}
                onDeleteAvailabilityException={onDeleteAvailabilityException}
                onSubmit={values => {
                  // We want to return the Promise to the form,
                  // so that it doesn't close its modal if an error is thrown.
                  return onCompleteEditListingWizardTab(tab, values, true);
                }}
                onNextTab={() =>
                  redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
                }
              />
            )}
            {currentactiveStep === 7 && (
              // <EditListingPhotosPanel
              //   {...panelProps(PHOTOS)}
              //   submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
              //   images={images}
              //   onImageUpload={onImageUpload}
              //   onRemoveImage={onRemoveImage}
              //   onSubmit={values => {
              //     onCompleteEditListingWizardTab(tab, values);
              //   }}
              //   onUpdateImageOrder={onUpdateImageOrder}
              // />
              <EditListingVideosPanel
                {...panelProps(VIDEOS)}
                submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
                onSubmit={values => {
                  onCompleteEditListingWizardTab('videos', values);
              }}
          />
            )}
            {currentactiveStep === 8 ? (
              <Box className={css.all_done_container}>
                <h2 className={css.all_done}>All Done!</h2>
                <Box className="check_icon">
                  <CheckCircle className={css.check_circle} />
                </Box>
              </Box>
            ) : (
              <Box></Box>
            )}
          </Box>
        )}
      </>
    );
  } else {
    switch (tab) {
      case DESCRIPTION: {
        const submitButtonTranslationKey = isNewListingFlow
          ? 'EditListingWizard.saveNewDescription'
          : 'EditListingWizard.saveEditDescription';
        return (
          <EditListingDescriptionPanel
            {...panelProps(DESCRIPTION)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
        );
      }
      case MUSIC_STYLE: {
        // const submitButtonTranslationKey = isNewListingFlow
        //   ? 'EditListingWizard.saveNewMusicStyles'
        //   : 'EditListingWizard.saveEditMusicStyles';
        populateMusicStyles();
        return (
          <Box className="select_music_styles edit_music_style">
            <h1>Select the music styles you are comfortable playing</h1>
            <Box sx={{ display: 'flex', margin: '5px' }} className="button_collection">
              <Multiselect
                className="select_music_type"
                options={options}
                selectedValues={selectedList.length > 0 ? selectedList : []}
                onSelect={(selectedList, selectedItem) => onSelect(selectedList, selectedItem)}
                onRemove={(selectedList, removedItem) => onRemove(selectedList, removedItem)}
                displayValue="name"
                placeholder="Select Music Styles"
                name="Music Style"
              />
            </Box>
            <Button
              className="btn_save_music_style"
              onClick={() => {
                const musicStyles = selectedList?.map(value => value.id);
                const updatedValues = {
                  publicData: { musicStyles },
                };
                setSubmitMusicStyles(true);
                onCompleteEditListingWizardTab(tab, updatedValues);
                setactiveStep(currentactiveStep + 1);
                props.setCurrentActiveStep(currentactiveStep + 1)
              }}
            >
              {submitMusicStyles ? <DoneIcon /> : 'Save'}
            </Button>
          </Box>
        );
      }
      case FEATURES: {
        const submitButtonTranslationKey = isNewListingFlow
          ? 'EditListingWizard.saveNewFeatures'
          : 'EditListingWizard.saveEditFeatures';
        return (
          <EditListingFeaturesPanel
            {...panelProps(FEATURES)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
        );
      }
      case POLICY: {
        const submitButtonTranslationKey = isNewListingFlow
          ? 'EditListingWizard.saveNewPolicies'
          : 'EditListingWizard.saveEditPolicies';
        return (
          <EditListingPoliciesPanel
            {...panelProps(POLICY)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
        );
      }
      case LOCATION: {
        const submitButtonTranslationKey = isNewListingFlow
          ? 'EditListingWizard.saveNewLocation'
          : 'EditListingWizard.saveEditLocation';
        return (
          <div className="Tabs_Custom">
            <EditListingLocationPanel
              {...panelProps(LOCATION)}
              submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
              onSubmit={values => {
                onCompleteEditListingWizardTab(tab, values);
              }}
              editmain={true}
            />
          </div>
        );
      }
      case PRICING: {
        const submitButtonTranslationKey = isNewListingFlow
          ? 'EditListingWizard.saveNewPricing'
          : 'EditListingWizard.saveEditPricing';
        return (
          <EditListingPricingPanel
            {...panelProps(PRICING)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
        );
      }
      case AVAILABILITY: {
        const submitButtonTranslationKey = isNewListingFlow
          ? 'EditListingWizard.saveNewAvailability'
          : 'EditListingWizard.saveEditAvailability';
        return (
          <EditListingAvailabilityPanel
            {...panelProps(AVAILABILITY)}
            fetchExceptionsInProgress={fetchExceptionsInProgress}
            availabilityExceptions={availabilityExceptions}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onAddAvailabilityException={onAddAvailabilityException}
            onDeleteAvailabilityException={onDeleteAvailabilityException}
            onSubmit={values => {
              // We want to return the Promise to the form,
              // so that it doesn't close its modal if an error is thrown.
              return onCompleteEditListingWizardTab(tab, values, true);
            }}
            onNextTab={() =>
              redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
            }
          />
        );
      }
      case PHOTOS: {
        const submitButtonTranslationKey = isNewListingFlow
          ? 'EditListingWizard.saveNewPhotos'
          : 'EditListingWizard.saveEditPhotos';

        return (
          <EditListingPhotosPanel
            {...panelProps(PHOTOS)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            images={images}
            onImageUpload={onImageUpload}
            onRemoveImage={onRemoveImage}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
            onUpdateImageOrder={onUpdateImageOrder}
          />
        );
      }
      case VIDEOS: {
        const submitButtonTranslationKey = isNewListingFlow
          ? 'EditListingWizard.saveNewVideos'
          : 'EditListingWizard.saveEditVideos';

        return (
          <EditListingVideosPanel
            {...panelProps(VIDEOS)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
        );
      }
      default:
        return null;
    }
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
};

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onUpdateListingPublicDta: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
