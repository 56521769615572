import React from 'react';
import Modal from '@material-ui/core/Modal';
import css from './OnBoarding.module.css';
import logo from '../Logo/logo.png';

const OnBoardingModal = props => {
  const {
    details: {
      title,
      desc,
      actionImg,
      actionBtnText,
      actionBtnHandler,
      showAbort,
      abortBtnText,
      abortBtnHandler,
      video,
    },
  } = props;
  return (
    <div>
      <Modal
        open={true}
        onClose={() => {}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={css.modalContentCenter}
      >
        <div className={css.modalContent}>
          <img src={logo} alt="" className={css.headerLogo} />

          {video ? (
            <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/YvLJ0i3MnJs"
              title="Worship Serve Intro"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              style={{borderRadius:10}}
            ></iframe>
          ) : (
            <img src={actionImg} alt="" className={css.actionImg} />
          )}
          <p className={css.title}>{title}</p>
          <p className={css.desc}>{desc} </p>
          <button className={css.button + ' ' + css.action} onClick={actionBtnHandler}>
            {actionBtnText}
          </button>
          {showAbort && (
            <button className={css.button} onClick={abortBtnHandler}>
              {abortBtnText}
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default OnBoardingModal;
