import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATETIME } from '../../util/types';
import { ensureListing } from '../../util/data';
import { BookingBreakdown } from '../../components';
import css from './TransactionPanel.module.css';

// Functional component as a helper to build BookingBreakdown

function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}
const BreakdownMaybe = props => {
  const { className, rootClassName, breakdownClassName, transaction, transactionRole, isTransaction } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';
  console.log('props Data', props);

  const reherical_date = isJsonString(props?.bookingData?.reherical_date) ? JSON.parse(props?.bookingData?.reherical_date) : {};
  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);
  return loaded ? (
    <div className={classes}>
      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={config.bookingUnitType}
        transaction={transaction}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
        bookingData={{
          ...props?.bookingData,
          reherical_date,
        }}
        selectedDates={props?.bookingData?.totalSelectedDate || []}
        selectedDate={transaction?.booking?.attributes?.displayStart }
        isTransaction={isTransaction}
        // onCancelSale={props.onCancelSale}
      />
    </div>
  ) : null;
};

export default BreakdownMaybe;
